import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { Alert, Card } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addBank, getOneBank, updateBank } from '../../features/bankSlice'
import { useEffect } from 'react'
import Loader from '../../components/Loader'

const CreateBank = () => {
  const navigate = useNavigate()
  const [error, setError] = useState(false)

  const { id } = useParams()

  const dispatch = useDispatch()
  const { bank, isLoading, addSuccess, updateSuccess } = useSelector(
    (state) => state.bank
  )

  const [data, setData] = useState({
    name: '',
    branchName: '',
    branchCode: '',
    address: '',
    manager: '',
    managerContact: '',
    branchTelNumber: '',
    email: '',
  })
  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }
  useEffect(() => {
    if (id) {
      dispatch(getOneBank({ id }))
    }
  }, [id, dispatch])
  useEffect(() => {
    if (id) {
      setData({
        name: bank.name,
        branchName: bank.branchName,
        branchCode: bank.branchCode,
        address: bank.address,
        manager: bank.manager,
        managerContact: bank.managerContact,
        branchTelNumber: bank.branchTelNumber,
        email: bank.email,
      })
    }
  }, [id, bank])

  useEffect(() => {
    const toastOptions = {
      position: 'top-right',
      autoClose: 8000,
      pauseOnHover: true,
      draggable: true,
      theme: 'light',
    }
    if (addSuccess) {
      toast.success('Bank Created', toastOptions)
      setData({
        name: '',
        branchName: '',
        branchCode: '',
        address: '',
        manager: '',
        managerContact: '',
        branchTelNumber: '',
        email: '',
      })
    }
    if (id && updateSuccess) {
      toast.success('Bank Updated', toastOptions)
      setData({
        name: '',
        branchName: '',
        branchCode: '',
        address: '',
        manager: '',
        managerContact: '',
        branchTelNumber: '',
        email: '',
      })
    }
  }, [id, dispatch, addSuccess, updateSuccess])

  const onSubmit = (e) => {
    e.preventDefault()
    if (id) {
      dispatch(updateBank({ id, data }))
    } else dispatch(addBank(data))
  }

  return (
    <div className='total-container'>
      <Button
        variant='light'
        onClick={() => navigate(-1)}
        className='mx-3 mb-3 px-4'
      >
        {'🡐 Back'}
      </Button>
      {isLoading ? (
        <Loader />
      ) : (
        <div className='d-flex my-5 justify-content-center align-items-center'>
          <Card className='col-12 col-md-7 col-lg-5 p-5 shadow'>
            {error && <Alert variant='danger'>{error}</Alert>}
            <Form onSubmit={onSubmit}>
              <h2 className='text-center'>Create Bank</h2>
              <hr />
              <Form.Group className='mb-3 w-100' controlId='formBasicEmail'>
                <Form.Label>Bank Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Bank Name'
                  className={`w-100 ${error && !data.name && 'is-invalid'}`}
                  name='name'
                  value={data.name}
                  onChange={onChange}
                />
                <div className='invalid-feedback'>This Field is required</div>
              </Form.Group>

              <div className=''>
                <Form.Group className='mb-3'>
                  <Form.Label>Branch Name</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Branch Name'
                    className={`w-100 ${
                      error && !data.branchName && 'is-invalid'
                    }`}
                    name='branchName'
                    value={data.branchName}
                    onChange={onChange}
                  />
                  <div className='invalid-feedback'>This Field is required</div>
                </Form.Group>
                <Form.Group className='mb-3'>
                  <Form.Label>Branch Code</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Branch Code'
                    className={`w-100 ${
                      error && !data.branchCode && 'is-invalid'
                    }`}
                    name='branchCode'
                    value={data.branchCode}
                    onChange={onChange}
                  />
                  <div className='invalid-feedback'>This Field is required</div>
                </Form.Group>
              </div>
              <Form.Group className='mb-3'>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Address'
                  as='textarea'
                  rows={3}
                  className={`w-100 ${error && !data.address && 'is-invalid'}`}
                  name='address'
                  value={data.address}
                  onChange={onChange}
                />
                <div className='invalid-feedback'>This Field is required</div>
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Branch Manager</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Branch Manager'
                  className={`w-100 ${error && !data.manager && 'is-invalid'}`}
                  name='manager'
                  value={data.manager}
                  onChange={onChange}
                />
                <div className='invalid-feedback'>This Field is required</div>
              </Form.Group>
              <div className=''>
                <Form.Group className='mb-3'>
                  <Form.Label>Manager Contact</Form.Label>
                  <Form.Control
                    type='tel'
                    placeholder='Enter Manager Contact'
                    className={`w-100 ${
                      error && !data.managerContact && 'is-invalid'
                    }`}
                    name='managerContact'
                    value={data.managerContact}
                    onChange={onChange}
                  />
                  <div className='invalid-feedback'>This Field is required</div>
                </Form.Group>
                <Form.Group className='mb-3'>
                  <Form.Label>Branch Telephone</Form.Label>
                  <Form.Control
                    type='tel'
                    placeholder='Enter Branch Telephone'
                    className={`w-100 ${
                      error && !data.branchTelNumber && 'is-invalid'
                    }`}
                    name='branchTelNumber'
                    value={data.branchTelNumber}
                    onChange={onChange}
                  />
                  <div className='invalid-feedback'>This Field is required</div>
                </Form.Group>
              </div>
              <Form.Group className='mb-3'>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type='email'
                  placeholder='Enter Email'
                  className={`w-100 ${error && !data.email && 'is-invalid'}`}
                  name='email'
                  value={data.email}
                  onChange={onChange}
                />
                <div className='invalid-feedback'>This Field is required</div>
              </Form.Group>
              <Button variant='primary' type='submit'>
                Submit
              </Button>
            </Form>
          </Card>
        </div>
      )}
    </div>
  )
}

export default CreateBank
