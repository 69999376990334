import React, { useEffect } from 'react'
import { useState } from 'react'
import { Alert, Button, Card, Form, FormCheck, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

import Loader from '../components/Loader'
import Paginate from '../components/Paginate'
import { resetState as resetEmployeeState } from '../features/employeeSlice'

import { getRepaymentList } from '../features/repaymentSlice'
import { resetState } from '../features/sanghaSlice'

const RepaymentList = () => {
  const [filter, setFilter] = useState({})
  const [viewTable, setViewTable] = useState(false)

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()

  const {
    repaymentList,
    currentPage,
    totalPages,
    bankData,
    employeeData,
    isLoading,
    error,
  } = useSelector((state) => state.repayment)
  const { user } = useSelector((state) => state.user)

  const onChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value })
  }
  const params = searchParams.toString()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getRepaymentList(params))
  }, [dispatch, params])

  const onSubmit = (e) => {
    e.preventDefault()
    setSearchParams(filter)
  }
  return (
    <div className='total-container'>
      <div className='d-flex align-items-center'>
        <Button
          variant='light'
          onClick={() => navigate(-1)}
          className='mx-3 mb-3 px-4'
        >
          {'🡐 Back'}
        </Button>
        <p>Repayment list</p>
      </div>

      {isLoading ? (
        <Loader />
      ) : error ? (
        <Alert variant='danger'>{error}</Alert>
      ) : (
        <>
          <div>
            <Form>
              <div className='d-flex flex-column flex-lg-row gap-5 '>
                <div className='d-flex flex-column flex-md-row gap-5 justify-content-around flex-grow-1 align-items-md-end'>
                  <Form.Group className='w-100'>
                    <Form.Select
                      onChange={onChange}
                      name='type'
                      value={filter.type}
                    >
                      <option value={''}>Select Search Type</option>
                      <option value='sangha'>Sangha</option>

                      <option value='bank'>Bank & Field Officer</option>
                    </Form.Select>
                  </Form.Group>
                  {filter.type === 'sangha' && (
                    <Form.Group className='w-100'>
                      <Form.Control
                        name='keyword'
                        type='text'
                        placeholder='Search Here'
                        onChange={onChange}
                        value={filter.keyword}
                      />
                    </Form.Group>
                  )}
                </div>
                <div className='d-flex flex-column flex-md-row gap-5 justify-content-around flex-grow-1 align-items-md-end'>
                  <Form.Group className='w-100'>
                    <Form.Label>From</Form.Label>
                    <Form.Control
                      name='fromDate'
                      type='date'
                      onChange={onChange}
                      value={filter.fromDate}
                    />
                  </Form.Group>
                  <Form.Group className='w-100'>
                    <Form.Label>To</Form.Label>
                    <Form.Control
                      name='toDate'
                      type='date'
                      onChange={onChange}
                      value={filter.toDate}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className='d-flex flex-column flex-md-row gap-5  flex-grow-1 align-items-md-end mt-4'>
                {filter.type === 'bank' && (
                  <>
                    <Form.Group className='w-100'>
                      <Form.Select
                        name='bankName'
                        onChange={onChange}
                        value={filter.bankName}
                      >
                        <option value=''>Select Bank</option>
                        {[
                          ...new Set(
                            bankData.map((bank) => {
                              return bank.name
                            })
                          ),
                        ].map((ele) => (
                          <option value={ele}>{ele}</option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className='w-100'>
                      <Form.Select
                        name='branchName'
                        onChange={onChange}
                        value={filter.branchName}
                      >
                        <option value=''>Select Branch</option>
                        {bankData
                          .filter((bank) => bank.name === filter.bankName)
                          .map((bank) => {
                            return (
                              <option value={bank.branchName}>
                                {bank.branchName}
                              </option>
                            )
                          })}
                      </Form.Select>
                    </Form.Group>
                    {(user.type === 'Admin' || user.type === 'Manager') && (
                      <Form.Group className='w-100'>
                        <Form.Label></Form.Label>
                        <Form.Select
                          name='keyword'
                          onChange={onChange}
                          value={filter.keyword}
                        >
                          <option>Select Field Officer</option>
                          {employeeData.map((employee) => {
                            return (
                              <option value={employee._id}>
                                {employee.name}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </Form.Group>
                    )}
                  </>
                )}
              </div>
              <div>
                <Form.Group style={{ maxWidth: '18rem' }} className='mt-5'>
                  <Form.Select onChange={onChange} name='loanClose'>
                    <option value=''>Select Loan Status</option>
                    <option value='ongoing'>ongoing</option>
                    <option value='closed'>closed</option>
                    <option value='all'>all</option>
                  </Form.Select>
                </Form.Group>
              </div>
              <div className='d-flex justify-content-center'>
                <Button
                  variant='primary'
                  className='mt-3 px-5'
                  onClick={onSubmit}
                >
                  Search
                </Button>
              </div>
            </Form>
          </div>
          <hr />

          <div>
            <FormCheck
              onChange={(e) => setViewTable(e.target.checked)}
              checked={viewTable}
              type='switch'
              id='custom-switch'
              label='Switch to Table'
            />
          </div>
          {repaymentList && repaymentList.length ? (
            <>
              {viewTable ? (
                <>
                  <Table responsive striped bordered hover className='mt-4'>
                    <thead>
                      <tr>
                        <th>Sangha Name</th>
                        <th>Repayment amount</th>
                        <th>Receipt Number</th>
                        <th>Bank Name</th>
                        <th>Branch Name</th>
                        <th>Address</th>
                        <th>Receipt Date</th>
                        {(user.type === 'Admin' || user.type === 'Manager') && (
                          <th>FieldOfficer</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {repaymentList &&
                        repaymentList.map((ele) => {
                          const receiptGenerationDate = new Date(
                            ele.receiptGenerationDate
                          )

                          var dd = String(
                            receiptGenerationDate.getDate()
                          ).padStart(2, '0')
                          var mm = String(
                            receiptGenerationDate.getMonth() + 1
                          ).padStart(2, '0') //January is 0!
                          var yyyy = receiptGenerationDate.getFullYear()
                          const date = dd + '/' + mm + '/' + yyyy
                          return (
                            <tr key={ele._id}>
                              <td>
                                <Link
                                  to={`/sangha-list/${
                                    ele.SanghaData[0] && ele.SanghaData[0]._id
                                  }`}
                                  // className='text-dark'
                                  // style={{ textDecoration: 'none' }}
                                >
                                  <p
                                    className='text-center'
                                    onClick={() => dispatch(resetState())}
                                  >
                                    {ele.SanghaData[0] &&
                                      ele.SanghaData[0].name}
                                  </p>
                                </Link>
                              </td>

                              <td className='text-center'>{ele.amount}</td>
                              <td className='text-center'>{ele.receiptId}</td>
                              <td className='text-center'>
                                {`${ele.BankData[0] && ele.BankData[0].name} `}
                              </td>
                              <td className='text-center'>
                                {` ${
                                  ele.BankData[0] && ele.BankData[0].branchName
                                }`}
                              </td>
                              <td className='text-center'>
                                {ele.SanghaData[0] && ele.SanghaData[0].address}
                              </td>
                              <td className='text-center'>{date}</td>
                              {(user.type === 'Admin' ||
                                user.type === 'Manager') && (
                                <td>
                                  <Link
                                    to={`/employee-list/${
                                      ele.EmployeeData[0] &&
                                      ele.EmployeeData[0]._id
                                    }`}
                                  >
                                    <p
                                      className='text-center'
                                      onClick={() => {
                                        dispatch(resetEmployeeState())
                                      }}
                                    >
                                      {ele.EmployeeData[0] &&
                                        ele.EmployeeData[0].name}
                                    </p>
                                  </Link>
                                </td>
                              )}
                            </tr>
                          )
                        })}
                    </tbody>
                  </Table>
                </>
              ) : (
                <>
                  <div className='d-flex justify-content-center flex-wrap'>
                    {repaymentList &&
                      repaymentList.map((ele) => {
                        const receiptGenerationDate = new Date(
                          ele.receiptGenerationDate
                        )

                        var dd = String(
                          receiptGenerationDate.getDate()
                        ).padStart(2, '0')
                        var mm = String(
                          receiptGenerationDate.getMonth() + 1
                        ).padStart(2, '0') //January is 0!
                        var yyyy = receiptGenerationDate.getFullYear()
                        const date = dd + '/' + mm + '/' + yyyy
                        return (
                          <div
                            className='col-12 col-md-4 col-lg-3 card-container'
                            key={ele._id}
                          >
                            <Card className='p-3 h-100'>
                              <Link
                                to={`/sangha-list/${
                                  ele.SanghaData[0] && ele.SanghaData[0]._id
                                }`}
                                // className='text-dark'
                                // style={{ textDecoration: 'none' }}
                              >
                                <h5
                                  className='text-center'
                                  onClick={() => dispatch(resetState())}
                                >
                                  {ele.SanghaData[0] && ele.SanghaData[0].name}
                                </h5>
                              </Link>
                              <hr />
                              <h6 className='text-center'>
                                Repayment amount : {ele.amount}
                              </h6>
                              <h6 className='text-center'>
                                Receipt Number : {ele.receiptId}
                              </h6>
                              <p className='text-center'>
                                Bank :{' '}
                                {`${
                                  ele.BankData[0] && ele.BankData[0].name
                                } , ${
                                  ele.BankData[0] && ele.BankData[0].branchName
                                }`}
                              </p>
                              <p className='text-center'>
                                Address :{' '}
                                {ele.SanghaData[0] && ele.SanghaData[0].address}
                              </p>
                              <p className='text-center'>
                                Receipt Date : {date}
                              </p>
                              {(user.type === 'Admin' ||
                                user.type === 'Manager') && (
                                <Link
                                  to={`/employee-list/${
                                    ele.EmployeeData[0] &&
                                    ele.EmployeeData[0]._id
                                  }`}
                                >
                                  <p
                                    className='text-center'
                                    onClick={() => {
                                      dispatch(resetEmployeeState())
                                    }}
                                  >
                                    FieldOfficer :{' '}
                                    {ele.EmployeeData[0] &&
                                      ele.EmployeeData[0].name}
                                  </p>
                                </Link>
                              )}
                            </Card>
                          </div>
                        )
                      })}
                  </div>
                </>
              )}

              <div className='d-flex justify-content-end me-3'>
                <Paginate page={currentPage} pages={totalPages} />
              </div>
            </>
          ) : (
            <>
              <h4 className='text-center'>No Data Found</h4>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default RepaymentList
