import React from 'react'
import { Pagination } from 'react-bootstrap'

import { useSearchParams } from 'react-router-dom'

const Paginate = ({ pages, page, type }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    pages > 1 && (
      <div style={{ maxWidth: '100%', overflow: 'auto' }}>
        <Pagination>
          {[...Array(pages).keys()].map((x) => {
            return (
              <Pagination.Item
                active={x + 1 === page}
                key={x + 1}
                onClick={() => {
                  if (type === 'lending')
                    searchParams.set('lendPageNumber', x + 1)
                  else if (type === 'receipt')
                    searchParams.set('receiptPageNumber', x + 1)
                  else if (type === 'incentive')
                    searchParams.set('empIncentivePageNumber', x + 1)
                  else if (type === 'paidIncentive')
                    searchParams.set('incentivePageNumber', x + 1)
                  else if (type === 'savings')
                    searchParams.set('currentSavingPage', x + 1)
                  else if (type === 'repayment')
                    searchParams.set('currentRepaymentPage', x + 1)
                  else searchParams.set('pageNumber', x + 1)
                  setSearchParams(searchParams)
                }}
              >
                {x + 1}
              </Pagination.Item>
            )
          })}
        </Pagination>
      </div>
    )
  )
}

export default Paginate
