import { useEffect } from 'react'
import { useState } from 'react'
import { Alert, Button, Card, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useSearchParams } from 'react-router-dom'
import Loader from '../components/Loader'
import { getHomeList } from '../features/homeSlice'

const HomePage = () => {
  const [filter, setFilter] = useState({})

  const [searchParams, setSearchParams] = useSearchParams()

  const {
    isLoading,
    TotalDueDemandData,
    TotalLoanDetails,
    TotalSecutityDeposit,
    TotalRiskFund,
    TotalSavingsAndRepaymentData,
    AllBankDetails,
    AllEmployeeDetails,
    TotalIncomeData,
    error,
  } = useSelector((state) => state.home)
  const { user } = useSelector((state) => state.user)

  const onChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value })
  }
  const params = searchParams.toString()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getHomeList(params))
  }, [dispatch, params])

  const onSubmit = (e) => {
    e.preventDefault()
    setSearchParams(filter)
  }

  return (
    <div className='total-container'>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Alert variant='danger'>{/* {error} */}</Alert>
      ) : (
        <>
          <div>
            <Form>
              <div className='d-flex flex-column flex-lg-row gap-5 '>
                <div className='d-flex flex-column flex-md-row gap-5 justify-content-around flex-grow-1 align-items-md-end'>
                  <Form.Group className='w-100'>
                    <Form.Select
                      onChange={onChange}
                      name='type'
                      value={filter.type}
                    >
                      <option value={''}>Select Search Type</option>
                      <option value='sangha'>Sangha</option>

                      <option value='bank'>Bank & Field Officer</option>
                    </Form.Select>
                  </Form.Group>
                  {filter.type === 'sangha' && (
                    <Form.Group className='w-100'>
                      <Form.Control
                        name='keyword'
                        type='text'
                        placeholder='Search Here'
                        onChange={onChange}
                        value={filter.keyword}
                      />
                    </Form.Group>
                  )}
                </div>
                <div className='d-flex flex-column flex-md-row gap-5 justify-content-around flex-grow-1 align-items-md-end'>
                  <Form.Group className='w-100'>
                    <Form.Label>From</Form.Label>
                    <Form.Control
                      name='fromDate'
                      type='date'
                      onChange={onChange}
                      value={filter.fromDate}
                    />
                  </Form.Group>
                  <Form.Group className='w-100'>
                    <Form.Label>To</Form.Label>
                    <Form.Control
                      name='toDate'
                      type='date'
                      onChange={onChange}
                      value={filter.toDate}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className='d-flex flex-column flex-md-row gap-5  flex-grow-1 align-items-md-end mt-4'>
                {filter.type === 'bank' && (
                  <>
                    <Form.Group className='w-100'>
                      <Form.Select
                        name='bankName'
                        onChange={onChange}
                        value={filter.bankName}
                      >
                        <option value=''>Select Bank</option>
                        {[
                          ...new Set(
                            AllBankDetails.map((bank) => {
                              return bank.name
                            })
                          ),
                        ].map((ele) => (
                          <option value={ele}>{ele}</option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className='w-100'>
                      <Form.Select
                        name='branchName'
                        onChange={onChange}
                        value={filter.branchName}
                      >
                        <option value=''>Select Branch</option>
                        {AllBankDetails.filter(
                          (bank) => bank.name === filter.bankName
                        ).map((bank) => {
                          return (
                            <option value={bank.branchName}>
                              {bank.branchName}
                            </option>
                          )
                        })}
                      </Form.Select>
                    </Form.Group>
                    {(user.type === 'Admin' || user.type === 'Manager') && (
                      <Form.Group className='w-100'>
                        <Form.Label></Form.Label>
                        <Form.Select
                          name='keyword'
                          onChange={onChange}
                          value={filter.keyword}
                        >
                          <option>Select Field Officer</option>
                          {AllEmployeeDetails.map((employee) => {
                            return (
                              <option value={employee._id}>
                                {employee.name}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </Form.Group>
                    )}
                  </>
                )}
              </div>

              <div className='d-flex justify-content-center'>
                <Button
                  variant='primary'
                  className='mt-3 px-5'
                  onClick={onSubmit}
                >
                  Search
                </Button>
              </div>
            </Form>
          </div>
          <hr />
          <div className='d-flex justify-content-center flex-wrap '>
            <div className='col-10 col-md-4 col-lg-3 card-container'>
              <Card className='p-3 h-100'>
                <h5 className='text-center'>Due Payment</h5>
                <hr />
                <h6 className='text-center'>
                  Amount : {TotalDueDemandData.TotalDuePayment}
                </h6>
                <p className='text-center'>
                  Sangha : {TotalDueDemandData.totlaDueSangha}
                </p>
                <Link to='/due-payment-list' className='w-100 mt-auto'>
                  <Button className='w-100 '>View More</Button>
                </Link>
              </Card>
            </div>
            <div className='col-10 col-md-4 col-lg-3  card-container'>
              <Card className='p-3 h-100'>
                <h5 className='text-center'>Demand List</h5>
                <hr />
                <h6 className='text-center'>
                  Amount : {TotalDueDemandData.TotalDemandList}
                </h6>
                <p className='text-center'>
                  Sangha : {TotalDueDemandData.totalDmandSangha}
                </p>
                <Link to='/demand-list' className='w-100 mt-auto'>
                  <Button className='w-100 '>View More</Button>
                </Link>
              </Card>
            </div>
            <div className='col-10 col-md-4 col-lg-3 card-container'>
              <Card className='p-3 h-100'>
                <h5 className='text-center'>Lending Report</h5>
                <hr />
                <h6 className='text-center'>
                  Amount :{' '}
                  {TotalLoanDetails[0] ? TotalLoanDetails[0].TotalLoan : 0}
                </h6>
                <p className='text-center'>
                  Sangha :{' '}
                  {TotalLoanDetails[0]
                    ? TotalLoanDetails[0].SanghaCountForLoanAmount
                    : 0}
                </p>
                <Link to='/lending-report-list' className='w-100 mt-auto'>
                  <Button className='w-100 '>View More</Button>
                </Link>
              </Card>
            </div>
            {(user.type === 'Admin' || user.type === 'Manager') && (
              <div className='col-10 col-md-4 col-lg-3 card-container'>
                <Card className='p-3 h-100'>
                  <h5 className='text-center'>Security Deposit</h5>
                  <hr />
                  <h6 className='text-center'>
                    Amount :{' '}
                    {TotalSecutityDeposit[0]
                      ? TotalSecutityDeposit[0].TotalSecurityDeposit
                      : 0}
                  </h6>
                  <p className='text-center'>
                    Sangha :{' '}
                    {TotalSecutityDeposit[0]
                      ? TotalSecutityDeposit[0].SanghaCountForSecurityDeposit
                      : 0}
                  </p>
                  <Link to='/security-deposit-list' className='w-100 mt-auto'>
                    <Button className='w-100 '>View More</Button>
                  </Link>
                </Card>
              </div>
            )}

            {(user.type === 'Admin' || user.type === 'Manager') && (
              <div className='col-10 col-md-4 col-lg-3 card-container'>
                <Card className='p-3 h-100'>
                  <h5 className='text-center'>Risk Fund</h5>
                  <hr />
                  <h6 className='text-center'>
                    Amount : {TotalRiskFund.totalRisk}
                  </h6>
                  <p className='text-center'>
                    Sangha : {TotalRiskFund.sanghaCountForRiskFund}
                  </p>
                  <Link to='/risk-fund-list' className='w-100 mt-auto'>
                    <Button className='w-100 mt-auto'>View More</Button>
                  </Link>
                </Card>
              </div>
            )}

            <div className='col-10 col-md-4 col-lg-3 card-container'>
              <Card className='p-3 h-100'>
                <h5 className='text-center'>Saving Amount</h5>
                <hr />
                <h6 className='text-center'>
                  Amount :{' '}
                  {TotalSavingsAndRepaymentData.find(
                    (ele) => ele._id === 'Savings'
                  ) &&
                    TotalSavingsAndRepaymentData.find(
                      (ele) => ele._id === 'Savings'
                    ).TotalLoan}
                </h6>
                <p className='text-center'>
                  Receipts :{' '}
                  {TotalSavingsAndRepaymentData.find(
                    (ele) => ele._id === 'Savings'
                  ) &&
                    TotalSavingsAndRepaymentData.find(
                      (ele) => ele._id === 'Savings'
                    ).receiptCount}
                </p>
                <Link to='/savings-list' className='w-100 mt-auto'>
                  <Button className='w-100 '>View More</Button>
                </Link>
              </Card>
            </div>
            <div className='col-10 col-md-4 col-lg-3 card-container'>
              <Card className='p-3 h-100'>
                <h5 className='text-center'>Repayment Amount</h5>
                <hr />
                <h6 className='text-center'>
                  Amount :{' '}
                  {TotalSavingsAndRepaymentData.find(
                    (ele) => ele._id === 'Loan'
                  ) &&
                    TotalSavingsAndRepaymentData.find(
                      (ele) => ele._id === 'Loan'
                    ).TotalLoan}
                </h6>
                <p className='text-center'>
                  Receipts :{' '}
                  {TotalSavingsAndRepaymentData.find(
                    (ele) => ele._id === 'Loan'
                  ) &&
                    TotalSavingsAndRepaymentData.find(
                      (ele) => ele._id === 'Loan'
                    ).receiptCount}
                </p>
                <Link to='/repayment-list' className='w-100 mt-auto'>
                  <Button className='w-100 '>View More</Button>
                </Link>
              </Card>
            </div>
            {(user.type === 'Admin' || user.type === 'Manager') && (
              <div className='col-10 col-md-4 col-lg-3 card-container'>
                <Card className='p-3 h-100'>
                  <h5 className='text-center'>Income Report</h5>
                  <hr />
                  <h6 className='text-center'>
                    Service Charge :{' '}
                    {TotalIncomeData[0]
                      ? TotalIncomeData[0].TotalServiceCharge
                      : 0}
                  </h6>

                  <h6 className='text-center'>
                    Enrollment Fee :{' '}
                    {TotalIncomeData[0]
                      ? TotalIncomeData[0].TotalEnrollmentFee
                      : 0}
                  </h6>
                  <p className='text-center'>
                    Sangha :{' '}
                    {TotalIncomeData[0]
                      ? TotalIncomeData[0].SanghaCountForLoanAmount
                      : 0}
                  </p>

                  <Link to='/income-report' className='w-100 mt-auto'>
                    <Button className='w-100 '>View More</Button>
                  </Link>
                </Card>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default HomePage
