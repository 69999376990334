import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from './axios/axios'
import { logout } from './userSlice'

const initialState = {
  demandList: [],
  demandPrintList: [],
  bankData: [],
  employeeData: [],
  error: null,
  isLoading: false,
  currentPage: 0,
  totalPages: 0,
  filter: '',
}

export const getDemandList = createAsyncThunk(
  'demand/getDemandList',
  async (obj, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    try {
      const { user } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.user.token}`,
        },
      }
      const res = await axios.get(`/api/home/demandlist?${obj}`, config)

      return fulfillWithValue(res.data)
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        dispatch(logout(error.response.data))
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const getDemandPrintList = createAsyncThunk(
  'demand/getDemandPrintList',
  async (obj, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    try {
      const { user } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.user.token}`,
        },
      }
      const res = await axios.get(`/api/print/printdemandlist?${obj}`, config)

      return fulfillWithValue(res.data)
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        dispatch(logout(error.response.data))
      }
      return rejectWithValue(error.response.data)
    }
  }
)

const demandSlice = createSlice({
  name: 'demand',
  initialState,
  reducers: {
    resetState: (state) => {
      state.error = ''
      state.addSuccess = false
      state.updateSuccess = false
    },
  },
  extraReducers: {
    [getDemandList.pending]: (state) => {
      state.isLoading = true
      state.error = ''
    },
    [getDemandList.fulfilled]: (state, action) => {
      state.isLoading = false
      state.demandList = action.payload.items
      state.employeeData = action.payload.EmployeeData
      state.bankData = action.payload.BankData
      state.currentPage = action.payload.currentPage
      state.totalPages = action.payload.totalPages
    },
    [getDemandPrintList.rejected]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    [getDemandPrintList.fulfilled]: (state, action) => {
      state.demandPrintList = action.payload.items
      state.error = ''
      state.filter = action.payload.Filter
    },
    [getDemandPrintList.rejected]: (state, action) => {
      state.error = action.payload
    },
  },
})

export default demandSlice.reducer
