import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from './axios/axios'
import { logout } from './userSlice'

const initialState = {
  lendingList: [],
  lendingPrintList: [],
  bankData: [],
  employeeData: [],
  error: null,
  isLoading: false,
  currentPage: 0,
  totalPages: 0,
  filter: '',
}

export const getLendingList = createAsyncThunk(
  'lending/getLendingList',
  async (obj, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    try {
      const { user } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.user.token}`,
        },
      }
      const res = await axios.get(`/api/home/lendingdata?${obj}`, config)

      return fulfillWithValue(res.data)
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        dispatch(logout(error.response.data))
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const getLendingPrintList = createAsyncThunk(
  'lending/getLendingPrintList',
  async (obj, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    try {
      const { user } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.user.token}`,
        },
      }
      const res = await axios.get(`/api/print/printlendingdata?${obj}`, config)

      return fulfillWithValue(res.data)
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

const lendingSlice = createSlice({
  name: 'lending',
  initialState,
  reducers: {
    resetState: (state) => {
      state.error = ''
      state.addSuccess = false
      state.updateSuccess = false
    },
  },
  extraReducers: {
    [getLendingList.pending]: (state) => {
      state.isLoading = true
      state.error = ''
    },
    [getLendingList.fulfilled]: (state, action) => {
      state.isLoading = false
      state.lendingList = action.payload.items
      state.employeeData = action.payload.EmployeeData
      state.bankData = action.payload.BankData
      state.currentPage = action.payload.currentPage
      state.totalPages = action.payload.totalPages
    },
    [getLendingList.rejected]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    [getLendingList.pending]: (state) => {
      state.isLoading = true
      state.error = ''
    },
    [getLendingPrintList.fulfilled]: (state, action) => {
      state.isLoading = false
      state.lendingPrintList = action.payload.items
      state.filter = action.payload.Filter
    },
    [getLendingPrintList.rejected]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export default lendingSlice.reducer
