import React from 'react'
import { Spinner } from 'react-bootstrap'

const Loader = () => {
  return (
    <div
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Spinner
        animation='border'
        variant='primary'
        style={{ width: '100px', height: '100px' }}
      />
    </div>
  )
}

export default Loader
