import { useEffect } from 'react'
import { useState } from 'react'
import { Card, Modal, Form, Button, Alert } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Loader from '../components/Loader'
import {
  addSangha,
  getOneSangha,
  getSanghaList,
  updateSangha,
} from '../features/sanghaSlice'
function CreateSangha() {
  const [modalShow, setModalShow] = useState(false)
  const [photo, setPhoto] = useState('')

  const { id } = useParams()

  const dispatch = useDispatch()

  const [data, setData] = useState({
    name: '',
    type: '',
    formationDate: '',
    newSangha: true,
    linkage: '',
    address: '',
    memberCount: undefined,
    representative1: {},
    representative2: {},
    member: [],
    bankName: '',
    loanAmount: undefined,
    loanDate: '',
    loanAccountNumber: '',
    savingsAccountNumber: '',
    repaymentAmount: undefined,
    repaymentStartDate: '',
    repaymentFixedDate: '',
    sanghaFormPhoto: [],
    securityDeposit: undefined,
    securityDepositDate: '',
    serviceCharge: undefined,
    enrollmentFee: undefined,
    riskFundStartDate: '',
  })

  const {
    employeeData,
    sangha,
    bankData,
    isLoading,
    error: addError,
    addSuccess,
    updateSuccess,
  } = useSelector((state) => state.sangha)

  const { user } = useSelector((state) => state.user)

  useEffect(() => {
    if (!bankData.length) {
      dispatch(getSanghaList())
    }
  }, [dispatch, bankData])

  useEffect(() => {
    if (id) {
      dispatch(getOneSangha({ id }))
    }
  }, [id, dispatch])

  useEffect(() => {
    if (id && sangha.items) {
      var aliveMembers = sangha.items.aliveMembers
        ? sangha.items.aliveMembers.filter((ele) => ele.memberType === 'normal')
        : []
      var deadMembers = sangha.items.deadMembers
        ? sangha.items.deadMembers.filter((ele) => ele.memberType === 'normal')
        : []
      setData({
        name: sangha.items.name,
        type: sangha.items.type,
        formationDate: sangha.items.formationDate.split('T')[0],
        newSangha: sangha.items.newSangha,
        linkage: sangha.items.linkage,
        address: sangha.items.address,
        memberCount: sangha.items.memberCount,
        representative1: sangha.items.representatives.find(
          (ele) => ele.memberType === 'rep1'
        ),
        representative2: sangha.items.representatives.find(
          (ele) => ele.memberType === 'rep2'
        ),
        member: [...aliveMembers, ...deadMembers],
        bankName: sangha.items.BankData[0] && sangha.items.BankData[0]._id,
        loanAmount: sangha.items.loanAmount,
        loanDate: sangha.items.loanDate.split('T')[0],
        loanAccountNumber: sangha.items.loanAccountNumber,
        savingsAccountNumber: sangha.items.savingsAccountNumber,
        repaymentAmount: sangha.items.repaymentAmount,
        repaymentStartDate: sangha.items.repaymentStartDate.split('T')[0],
        repaymentFixedDate: sangha.items.repaymentFixedDate,
        sanghaFormPhoto: sangha.items.sanghaFormPhoto,
        securityDeposit: sangha.items.securityDeposit,
        securityDepositDate: sangha.items.securityDepositDate.split('T')[0],
        serviceCharge: sangha.items.serviceCharge,
        enrollmentFee: sangha.items.enrollmentFee,
        riskFundStartDate: sangha.items.riskFundStartDate.split('T')[0],
        fieldOfficer: sangha.items.fieldOfficer,
      })
    }
  }, [id, sangha])

  useEffect(() => {
    const toastOptions = {
      position: 'top-right',
      autoClose: 8000,
      pauseOnHover: true,
      draggable: true,
      theme: 'light',
    }
    if (addSuccess) {
      toast.success('Sangha Created', toastOptions)
      setData({
        name: '',
        type: '',
        formationDate: '',
        newSangha: true,
        linkage: '',
        address: '',
        memberCount: undefined,
        representative1: {},
        representative2: {},
        member: [],
        bankName: '',
        loanAmount: undefined,
        loanDate: '',
        loanAccountNumber: '',
        savingsAccountNumber: '',
        repaymentAmount: undefined,
        repaymentStartDate: '',
        repaymentFixedDate: '',
        sanghaFormPhoto: [],
        securityDeposit: undefined,
        securityDepositDate: '',
        serviceCharge: undefined,
        enrollmentFee: undefined,
        riskFundStartDate: '',
      })
    }
    if (id && updateSuccess) {
      toast.success('Sangha Updated', toastOptions)
      setData({
        name: '',
        type: '',
        formationDate: '',
        newSangha: true,
        linkage: '',
        address: '',
        memberCount: undefined,
        representative1: {},
        representative2: {},
        member: [],
        bankName: '',
        loanAmount: undefined,
        loanDate: '',
        loanAccountNumber: '',
        savingsAccountNumber: '',
        repaymentAmount: undefined,
        repaymentStartDate: '',
        repaymentFixedDate: '',
        sanghaFormPhoto: [],
        securityDeposit: undefined,
        securityDepositDate: '',
        serviceCharge: undefined,
        enrollmentFee: undefined,
        riskFundStartDate: '',
      })
    }
  }, [id, dispatch, addSuccess, updateSuccess])

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const [error, setError] = useState(false)

  const onSubmit = (e) => {
    e.preventDefault()

    if (id) {
      dispatch(updateSangha({ id, data }))
    } else dispatch(addSangha(data))
  }

  const [memberId, setMemberId] = useState(-1)
  const [type, setType] = useState('')
  const [member, setMember] = useState({
    memberName: '',
    village: '',
    fatherOrSpouseName: '',
    DOB: '',
    category: '',
    contactNo: '',
    amount: undefined,
  })

  const addUpdateMember = (member) => {
    if (type === 'representative1') {
      setData({ ...data, representative1: { ...member, memberType: 'rep1' } })
    } else if (type === 'representative2') {
      setData({ ...data, representative2: { ...member, memberType: 'rep2' } })
    } else if (type === 'member') {
      if (memberId > -1) {
        data.member[memberId] = { ...member, memberType: 'normal' }
        setData({ ...data })
      } else
        setData({
          ...data,
          member: [
            ...data.member,
            {
              ...member,
              memberType:
                type === 'representative1'
                  ? 'rep1'
                  : type === 'representative2'
                  ? 'rep2'
                  : 'normal',
            },
          ],
        })
    }
    setMember({
      memberName: '',
      village: '',
      fatherOrSpouseName: '',
      DOB: '',
      category: '',
      contactNo: '',
      amount: undefined,
    })
    setMemberId(-1)
  }
  const navigate = useNavigate()
  return (
    <div className='total-container'>
      <Button
        variant='light'
        onClick={() => navigate(-1)}
        className='mx-3 mb-3 px-4'
      >
        {'🡐 Back'}
      </Button>
      {isLoading ? (
        <Loader />
      ) : (
        <div className='d-flex my-5 justify-content-center align-items-center'>
          <Card className='col-12 col-md-9 col-lg-6 p-4 shadow'>
            {addError && <Alert variant='danger'>{addError}</Alert>}
            <Form onSubmit={onSubmit}>
              <h2 className='text-center'>Create Sangha</h2>
              <hr />
              <h5 className='text-center'>Loan Details</h5>
              <hr />
              <div>
                <Form.Group className='mb-3 w-100' controlId='formBasicEmail'>
                  <Form.Label>Bank Name</Form.Label>
                  <Form.Select
                    onChange={onChange}
                    name='bankName'
                    value={data.bankName}
                    className={`w-100 ${
                      error && !data.bankName && 'is-invalid'
                    }`}
                  >
                    <option value=''>Select Bank</option>
                    {bankData.map((ele, id) => {
                      return (
                        <option value={ele._id} key={`B-${id}`}>
                          {ele.name} , {ele.branchName}
                        </option>
                      )
                    })}
                  </Form.Select>
                  <div className='invalid-feedback'>This Field is required</div>
                </Form.Group>
              </div>
              <div>
                <Form.Group className='mb-3 w-100'>
                  <Form.Label>Loan Amount</Form.Label>
                  <Form.Control
                    type='number'
                    placeholder='Enter Loan Amount'
                    min={1}
                    onChange={onChange}
                    name='loanAmount'
                    value={data.loanAmount || ''}
                    className={`w-100 ${
                      error && !data.loanAmount && 'is-invalid'
                    }`}
                  />
                  <div className='invalid-feedback'>This Field is required</div>
                </Form.Group>
                <Form.Group className='mb-3 w-100'>
                  <Form.Label>Loan Date</Form.Label>
                  <Form.Control
                    type='date'
                    placeholder='Enter Linkage'
                    min={1}
                    onChange={onChange}
                    name='loanDate'
                    value={data.loanDate}
                    className={`w-100 ${
                      error && !data.loanDate && 'is-invalid'
                    }`}
                  />
                  <div className='invalid-feedback'>This Field is required</div>
                </Form.Group>
              </div>
              <Form.Group className='mb-3'>
                <Form.Label>Loan Account Number</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Loan Account Number'
                  onChange={onChange}
                  name='loanAccountNumber'
                  value={data.loanAccountNumber}
                  className={`w-100 ${
                    error && !data.loanAccountNumber && 'is-invalid'
                  }`}
                />
                <div className='invalid-feedback'>This Field is required</div>
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Savings Account Number</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Savings Account Number'
                  onChange={onChange}
                  name='savingsAccountNumber'
                  value={data.savingsAccountNumber}
                  className={`w-100 ${
                    error && !data.savingsAccountNumber && 'is-invalid'
                  }`}
                />
                <div className='invalid-feedback'>This Field is required</div>
              </Form.Group>
              <hr />
              <h5 className='text-center'>Repayment Details</h5>
              <hr />
              <Form.Group className='mb-3 w-100'>
                <Form.Label>Repayment Amount</Form.Label>
                <Form.Control
                  type='number'
                  placeholder='Enter Repayment Amount'
                  min={1}
                  onChange={onChange}
                  name='repaymentAmount'
                  value={data.repaymentAmount || ''}
                  className={`w-100 ${
                    error && !data.repaymentAmount && 'is-invalid'
                  }`}
                />
                <div className='invalid-feedback'>This Field is required</div>
              </Form.Group>
              <div>
                <Form.Group className='mb-3 w-100'>
                  <Form.Label>Repayment Start Date</Form.Label>
                  <Form.Control
                    type='date'
                    name='repaymentStartDate'
                    value={data.repaymentStartDate}
                    className={`w-100 ${
                      error && !data.repaymentStartDate && 'is-invalid'
                    }`}
                    onChange={onChange}
                  />
                  <div className='invalid-feedback'>This Field is required</div>{' '}
                </Form.Group>
                <Form.Group className='mb-3 w-100'>
                  <Form.Label>Repayment Fixed Date</Form.Label>
                  <Form.Control
                    type='number'
                    min={1}
                    max={31}
                    onChange={onChange}
                    name='repaymentFixedDate'
                    value={data.repaymentFixedDate || ''}
                    className={`w-100 ${
                      error && !data.repaymentFixedDate && 'is-invalid'
                    }`}
                  />
                  <div className='invalid-feedback'>This Field is required</div>
                </Form.Group>
              </div>
              <hr />
              <h5 className='text-center'>Sangha Details</h5>
              <hr />
              <Form.Group className='mb-3'>
                <Form.Label>Sangha Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Sangha Name'
                  onChange={onChange}
                  name='name'
                  value={data.name}
                  className={`w-100 ${error && !data.name && 'is-invalid'}`}
                />
                <div className='invalid-feedback'>This Field is required</div>
              </Form.Group>
              <div>
                <Form.Group className='mb-3 w-100' controlId='formBasicEmail'>
                  <Form.Label>Sangha Type</Form.Label>
                  <Form.Select
                    onChange={onChange}
                    name='type'
                    value={data.type}
                    className={`w-100 ${error && !data.type && 'is-invalid'}`}
                  >
                    <option value=''>Select Type</option>
                    <option value='SHG'>SHG</option>
                    <option value='JLG'>JLG</option>
                  </Form.Select>
                  <div className='invalid-feedback'>This Field is required</div>
                </Form.Group>
                <Form.Group className='mb-3 w-100'>
                  <Form.Label>Formation Date</Form.Label>
                  <Form.Control
                    type='date'
                    placeholder='Enter Formation Date'
                    onChange={onChange}
                    name='formationDate'
                    value={data.formationDate}
                    className={`w-100 ${
                      error && !data.formationDate && 'is-invalid'
                    }`}
                  />
                  <div className='invalid-feedback'>This Field is required</div>
                </Form.Group>
              </div>
              <div className='d-md-flex gap-5 align-items-center'>
                <Form.Group className='mb-3 w-100'>
                  <Form.Check
                    type={'checkbox'}
                    label='new Sangha'
                    onChange={(e) =>
                      setData({ ...data, newSangha: e.target.checked })
                    }
                    name='newSangha'
                    checked={data.newSangha}
                    className={`w-100 `}
                  />
                </Form.Group>
                <Form.Group className='mb-3 w-100'>
                  <Form.Label>Linkage</Form.Label>
                  <Form.Control
                    type='number'
                    placeholder='Enter Linkage'
                    min={1}
                    onChange={onChange}
                    name='linkage'
                    value={data.linkage || ''}
                    className={`w-100 ${
                      error && !data.linkage && 'is-invalid'
                    }`}
                  />
                  <div className='invalid-feedback'>This Field is required</div>
                </Form.Group>
              </div>
              <Form.Group className='mb-3'>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Address'
                  as='textarea'
                  rows={3}
                  onChange={onChange}
                  name='address'
                  value={data.address}
                  className={`w-100 ${error && !data.address && 'is-invalid'}`}
                />
                <div className='invalid-feedback'>This Field is required</div>
              </Form.Group>
              <Form.Group className='mb-3 w-100'>
                <Form.Label>Member Count</Form.Label>
                <Form.Control
                  type='number'
                  placeholder='Enter Member Count'
                  min={0}
                  onChange={onChange}
                  name='memberCount'
                  value={data.memberCount || ''}
                  className={`w-100 ${
                    error && !data.memberCount && 'is-invalid'
                  }`}
                />
                <div className='invalid-feedback'>This Field is required</div>
              </Form.Group>
              <div>
                <hr />
                <h5 className='text-center'>Member List</h5>
                <hr />

                <div className='mt-3 d-flex gap-3 align-items-end'>
                  <div className='w-100'>
                    <Form.Label>Representative 1</Form.Label>
                  </div>
                  <div className='w-100'>
                    {data.representative1.memberName ? (
                      <div className='d-flex align-items-center'>
                        <div
                          className='cursor-pointer px-1  py-1 border'
                          onClick={() => {
                            setType('representative1')
                            setModalShow(true)
                            setMember({
                              ...data.representative1,
                            })
                          }}
                        >
                          {data.representative1.memberName}
                        </div>
                        <Button
                          variant='light'
                          className='rounded-0'
                          onClick={() =>
                            setData({ ...data, representative1: {} })
                          }
                        >
                          ×
                        </Button>
                      </div>
                    ) : (
                      <Button
                        onClick={() => {
                          setType('representative1')
                          setModalShow(true)
                          setMember({
                            memberName: '',
                            village: '',
                            fatherOrSpouseName: '',
                            DOB: '',
                            category: '',
                            contactNo: '',
                            amount: '',
                          })
                        }}
                      >
                        Add Representative 1
                      </Button>
                    )}
                  </div>
                </div>
                <div className='mt-3 d-flex gap-3 align-items-end'>
                  <div className='w-100'>
                    <Form.Label>Representative 2</Form.Label>
                  </div>
                  <div className='w-100'>
                    {data.representative2.memberName ? (
                      <div className='d-flex align-items-center'>
                        <div
                          className='cursor-pointer px-1  py-1 border'
                          onClick={() => {
                            setType('representative2')
                            setModalShow(true)
                            setMember({
                              ...data.representative2,
                            })
                          }}
                        >
                          {data.representative2.memberName}
                        </div>
                        <Button
                          variant='light'
                          className='rounded-0'
                          onClick={() =>
                            setData({ ...data, representative2: {} })
                          }
                        >
                          ×
                        </Button>
                      </div>
                    ) : (
                      <Button
                        onClick={() => {
                          setType('representative2')
                          setModalShow(true)
                          setMember({
                            memberName: '',
                            village: '',
                            fatherOrSpouseName: '',
                            DOB: '',
                            category: '',
                            contactNo: '',
                            amount: '',
                          })
                        }}
                      >
                        Add Representative 2
                      </Button>
                    )}
                  </div>
                </div>

                <div className='mt-3 d-flex gap-3 '>
                  <div className='w-100'>
                    <Form.Label>Other Members</Form.Label>
                    <Button
                      onClick={() => {
                        setType('member')
                        setModalShow(true)
                        setMember({
                          memberName: '',
                          village: '',
                          fatherOrSpouseName: '',
                          DOB: '',
                          category: '',
                          contactNo: '',
                          amount: '',
                        })
                      }}
                    >
                      Add Member
                    </Button>
                    <AddMember
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                      type={type}
                      member={member}
                      addUpdateMember={addUpdateMember}
                    />
                  </div>
                  <div className='w-100 flex-wrap d-flex gap-3 align-items-end'>
                    {data.member.map((member, id) => (
                      <div className='d-flex align-items-center'>
                        <div
                          className='cursor-pointer px-1  py-1 border'
                          onClick={() => {
                            setType('member')
                            setModalShow(true)
                            setMember({ ...member })
                            setMemberId(id)
                          }}
                        >
                          {member.memberName}
                        </div>
                        <Button
                          variant='light'
                          className='rounded-0'
                          onClick={() =>
                            setData({
                              ...data,
                              member: data.member.filter(
                                (ele, eleId) => eleId !== id
                              ),
                            })
                          }
                        >
                          ×
                        </Button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <hr />

              <h5 className='text-center'>Deposits and Fees</h5>
              <hr />
              <div>
                <Form.Group className='mb-3 w-100'>
                  <Form.Label>Security Depoist</Form.Label>
                  <Form.Control
                    type='number'
                    placeholder='Enter Security Depoist Amount'
                    min={0}
                    onChange={onChange}
                    name='securityDeposit'
                    value={data.securityDeposit || ''}
                    className={`w-100 ${
                      error && !data.securityDeposit && 'is-invalid'
                    }`}
                  />
                  <div className='invalid-feedback'>This Field is required</div>
                </Form.Group>
                <Form.Group className='mb-3 w-100'>
                  <Form.Label>Security Depoist Date</Form.Label>
                  <Form.Control
                    type='date'
                    onChange={onChange}
                    name='securityDepositDate'
                    value={data.securityDepositDate}
                    className={`w-100 ${
                      error && !data.securityDepositDate && 'is-invalid'
                    }`}
                  />
                  <div className='invalid-feedback'>This Field is required</div>
                </Form.Group>
                <Form.Group className='mb-3 w-100'>
                  <Form.Label>Risk Fund Start Date</Form.Label>
                  <Form.Control
                    type='date'
                    onChange={onChange}
                    name='riskFundStartDate'
                    value={data.riskFundStartDate}
                    className={`w-100 ${
                      error && !data.riskFundStartDate && 'is-invalid'
                    }`}
                  />
                  <div className='invalid-feedback'>This Field is required</div>
                </Form.Group>
              </div>
              <Form.Group className='mb-3 w-100'>
                <Form.Label>Service Charges</Form.Label>
                <Form.Control
                  type='number'
                  placeholder='Enter Service Charges Amount'
                  min={0}
                  onChange={onChange}
                  name='serviceCharge'
                  value={data.serviceCharge || ''}
                  className={`w-100 ${
                    error && !data.serviceCharge && 'is-invalid'
                  }`}
                />
                <div className='invalid-feedback'>This Field is required</div>
              </Form.Group>
              <Form.Group className='mb-3 w-100'>
                <Form.Label>Enrollment Fee</Form.Label>
                <Form.Control
                  type='number'
                  placeholder='Enter Enrollment Fee Amount'
                  min={0}
                  onChange={onChange}
                  name='enrollmentFee'
                  value={data.enrollmentFee || ''}
                  className={`w-100 ${
                    error && !data.enrollmentFee && 'is-invalid'
                  }`}
                />
                <div className='invalid-feedback'>This Field is required</div>
              </Form.Group>
              {(user.type === 'Admin' || user.type === 'Manager') && (
                <Form.Group className='mb-3 w-100' controlId='formBasicEmail'>
                  <Form.Label>Field Officer</Form.Label>
                  <Form.Select
                    onChange={onChange}
                    name='fieldOfficer'
                    value={data.fieldOfficer}
                    className={`w-100 ${
                      error && !data.fieldOfficer && 'is-invalid'
                    }`}
                  >
                    <option value=''>Select Field Officer</option>
                    {employeeData.map((ele, id) => {
                      return (
                        <option value={ele._id} key={`FO-${id}`}>
                          {ele.name}
                        </option>
                      )
                    })}
                  </Form.Select>
                  <div className='invalid-feedback'>This Field is required</div>
                </Form.Group>
              )}

              <div className='d-md-flex gap-5 align-items-end'>
                <Form.Group className='mb-3 w-100'>
                  <Form.Label>Form Photo Link</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Form Photo Link'
                    onChange={(e) => setPhoto(e.target.value)}
                    value={photo}
                  />
                </Form.Group>
                <div className='mb-3 '>
                  <Button
                    onClick={() => {
                      setData({
                        ...data,
                        sanghaFormPhoto: [...data.sanghaFormPhoto, photo],
                      })
                      setPhoto('')
                    }}
                  >
                    Add
                  </Button>
                </div>
              </div>
              <div className='mb-3 d-flex flex-wrap gap-3'>
                {data.sanghaFormPhoto[0] &&
                  data.sanghaFormPhoto.map((ele, id) => {
                    return (
                      <div className='d-flex flex-column gap-2'>
                        <img
                          src={ele}
                          alt={`form${id}`}
                          style={{ height: '120px' }}
                        />
                        <Button
                          variant='danger'
                          onClick={() => {
                            setData({
                              ...data,
                              sanghaFormPhoto: data.sanghaFormPhoto.filter(
                                (ele, eleId) => eleId !== id
                              ),
                            })
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    )
                  })}
              </div>

              <Button variant='primary' type='submit'>
                Submit
              </Button>
            </Form>
          </Card>
        </div>
      )}
    </div>
  )
}

function AddMember({ member, type, addUpdateMember, ...props }) {
  const [memberData, setMemberData] = useState({
    memberName: '',
    village: '',
    fatherOrSpouseName: '',
    DOB: '',
    category: '',
    contactNo: '',
    amount: undefined,
  })

  const onChange = (e) => {
    setMemberData({ ...memberData, [e.target.name]: e.target.value })
  }

  const [error, setError] = useState(false)

  useEffect(() => {
    setMemberData({
      ...member,
      memberName: member.memberName || '',
      village: member.village || '',
      fatherOrSpouseName: member.fatherOrSpouseName || '',
      DOB: (member.DOB && member.DOB.split('T')[0]) || '',
      category: member.category || 'na',
      contactNo: member.contactNo || '',

      amount: member.amount
        ? member.amount
        : member.riskFund
        ? member.riskFund[member.riskFund.length - 1].amount
        : '',
    })
  }, [member])

  const onSubmit = (e) => {
    e.preventDefault()
    var temp = false
    for (const key in memberData) {
      if (!memberData[key]) {
        temp = true
        break
      }
    }

    if (!temp) {
      props.onHide()
      addUpdateMember({ ...memberData })
      setMemberData({
        memberName: '',
        village: '',
        fatherOrSpouseName: '',
        DOB: '',
        category: 'na',
        contactNo: '',
        amount: undefined,
      })
    }
    setError(temp)
  }
  return (
    <Modal
      {...props}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>Add Member</Modal.Title>
      </Modal.Header>
      <Form>
        <Modal.Body>
          <Form.Group className='mb-3 w-100'>
            <Form.Label>Member Name</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter Member Name'
              onChange={onChange}
              name='memberName'
              value={memberData.memberName}
              className={`w-100 ${
                error && !memberData.memberName && 'is-invalid'
              }`}
            />
            <div className='invalid-feedback'>This Field is required</div>
          </Form.Group>
          <Form.Group className='mb-3 w-100'>
            <Form.Label>Village</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter Village'
              onChange={onChange}
              name='village'
              value={memberData.village}
              className={`w-100 ${
                error && !memberData.village && 'is-invalid'
              }`}
            />
            <div className='invalid-feedback'>This Field is required</div>
          </Form.Group>
          <Form.Group className='mb-3 w-100'>
            <Form.Label>Father/Mother/Spouce Name</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter Dependent Name'
              onChange={onChange}
              name='fatherOrSpouseName'
              value={memberData.fatherOrSpouseName}
              className={`w-100 ${
                error && !memberData.fatherOrSpouseName && 'is-invalid'
              }`}
            />
            <div className='invalid-feedback'>This Field is required</div>
          </Form.Group>
          <Form.Group className='mb-3 w-100'>
            <Form.Label>DOB</Form.Label>
            <Form.Control
              type='date'
              placeholder='Enter DOB'
              onChange={onChange}
              name='DOB'
              value={memberData.DOB}
              className={`w-100 ${error && !memberData.DOB && 'is-invalid'}`}
            />
            <div className='invalid-feedback'>This Field is required</div>
          </Form.Group>

          <Form.Group className='mb-3 w-100'>
            <Form.Label>Contact</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter Contact Number'
              onChange={onChange}
              name='contactNo'
              value={memberData.contactNo}
              className={`w-100 ${
                error && !memberData.contactNo && 'is-invalid'
              }`}
            />
            <div className='invalid-feedback'>This Field is required</div>
          </Form.Group>
          <Form.Group className='mb-3 w-100'>
            <Form.Label>Risk Amount</Form.Label>
            <Form.Control
              type='number'
              placeholder='Enter Risk Amount'
              min={0}
              onChange={onChange}
              name='amount'
              value={memberData.amount || ''}
              className={`w-100 ${error && !memberData.amount && 'is-invalid'}`}
            />
            <div className='invalid-feedback'>This Field is required</div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onSubmit}>Submit</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default CreateSangha
