import React from 'react'
import { Container, Nav, Navbar, NavDropdown, Offcanvas } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { logout } from '../features/userSlice'
import logo from '../Logo.png'
import './header.css'

const Header = () => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.user)

  return (
    <Navbar bg='dark' expand='lg' variant='dark'>
      <Container>
        <LinkContainer to='/'>
          <Navbar.Brand>
            <img
              fluid='true'
              src={logo}
              className='logo me-2'
              alt='SnehaJeevana'
            />
            SJF
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls='offcanvasNavbar-expand' />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand`}
          aria-labelledby={`offcanvasNavbarLabel-expand`}
          placement='end'
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand`}>
              SnehaJeevana
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className='ms-auto'>
              {user ? (
                <>
                  {' '}
                  <LinkContainer to='/'>
                    <Nav.Link>Home</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to='/sangha-list'>
                    <Nav.Link>Sangha</Nav.Link>
                  </LinkContainer>
                  {user && user.type === 'Admin' && (
                    <>
                      <LinkContainer to='/employee-list'>
                        <Nav.Link>Employee</Nav.Link>
                      </LinkContainer>
                      <LinkContainer to='/bank-list'>
                        <Nav.Link>Bank</Nav.Link>
                      </LinkContainer>
                    </>
                  )}
                  <NavDropdown title={user.username} id='basic-nav-dropdown'>
                    <LinkContainer to='/user-profile'>
                      <NavDropdown.Item>Profile</NavDropdown.Item>
                    </LinkContainer>

                    <NavDropdown.Item onClick={() => dispatch(logout())}>
                      LogOut
                    </NavDropdown.Item>
                  </NavDropdown>
                </>
              ) : (
                <LinkContainer to='/login'>
                  <Nav.Link>Login</Nav.Link>
                </LinkContainer>
              )}
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  )
}

export default Header
