import React from 'react'

const SoftwareDownload = () => {
  return (
    <div className='mt-5'>
      <h5>Windows</h5>
      <a
        href='https://drive.google.com/file/d/1WgQ0Zh_c4mJEXLx_1A-ior7sylC1qvcH/view?usp=share_link'
        target='_blank'
        rel='noreferrer'
      >
        Download
      </a>
      <h5>Android</h5>
      <a
        href='https://play.google.com/store/apps/details?id=com.loopedlabs.escposprintservice'
        rel='noreferrer'
        target='_blank'
      >
        Download
      </a>
    </div>
  )
}

export default SoftwareDownload
