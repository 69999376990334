import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from './axios/axios'
import { logout } from './userSlice'

const initialState = {
  error: null,
  isLoading: false,
  TotalDueDemandData: {},
  TotalLoanDetails: [],
  TotalSecutityDeposit: [],
  TotalRiskFund: {},
  TotalSavingsAndRepaymentData: [],
  AllBankDetails: [],
  AllEmployeeDetails: [],
  TotalIncomeData: [],
}

export const getHomeList = createAsyncThunk(
  'home/getHomeList',
  async (obj, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    try {
      const { user } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.user.token}`,
        },
      }
      const res = await axios.get(`/api/home?${obj}`, config)

      return fulfillWithValue(res.data)
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        dispatch(logout(error.response.data))
      }
      return rejectWithValue(error.response.data)
    }
  }
)

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    resetState: (state) => {
      state.error = null
      state.isLoading = false
      state.TotalDueDemandData = {}
      state.TotalLoanDetails = []
      state.TotalSecutityDeposit = []
      state.TotalRiskFund = {}
      state.TotalSavingsAndRepaymentData = []
      state.AllBankDetails = []
      state.AllEmployeeDetails = []
      state.TotalIncomeData = []
    },
  },
  extraReducers: {
    [getHomeList.pending]: (state) => {
      state.isLoading = true
      state.error = ''
    },
    [getHomeList.fulfilled]: (state, action) => {
      state.isLoading = false
      state.TotalDueDemandData = action.payload.TotalDueDemandData
      state.TotalLoanDetails = action.payload.TotalLoanDetails
      state.TotalSecutityDeposit = action.payload.TotalSecutityDeposit
      state.TotalRiskFund = action.payload.TotalRiskFund
      state.TotalSavingsAndRepaymentData =
        action.payload.TotalSavingsAndRepaymentData
      state.AllBankDetails = action.payload.AllBankDetails
      state.AllEmployeeDetails = action.payload.AllEmployeeDetails
      state.TotalIncomeData = action.payload.TotalIncomeData
    },
    [getHomeList.rejected]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export default homeSlice.reducer
