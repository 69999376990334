import React, { useEffect } from 'react'
import { useState } from 'react'
import { Alert, Button, Card, Collapse, Form, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { Link, useNavigate } from 'react-router-dom'
import Loader from '../components/Loader'
import Paginate from '../components/Paginate'
import { toast } from 'react-toastify'
import img from '../Logo.png'
import { getUserProfile, updatePass } from '../features/userSlice'

const UserProfile = () => {
  const [showRepayment, setShowRepayment] = useState(false)
  const [showSangha, setShowSangha] = useState(false)
  const [changePassShow, setChangePassShow] = useState(false)

  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const params = searchParams.toString()

  const { isLoading, error, userInfo, updatePassSuccess } = useSelector(
    (state) => state.user
  )

  useEffect(() => {
    const toastOptions = {
      position: 'top-right',
      autoClose: 8000,
      pauseOnHover: true,
      draggable: true,
      theme: 'light',
    }
    if (updatePassSuccess) toast.success('Password Updated', toastOptions)
  }, [updatePassSuccess])

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getUserProfile({ params }))
  }, [dispatch, params])

  return (
    <div className='total-container'>
      <Button
        variant='light'
        onClick={() => navigate(-1)}
        className='mx-3 mb-3 px-4'
      >
        {'🡐 Back'}
      </Button>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Alert variant='error'>{error}</Alert>
      ) : (
        <>
          <div className='mx-3 d-flex flex-wrap gap-3 justify-content-between'>
            <h4>Employee Details</h4>
          </div>
          <hr />
          <div className='mx-3 d-flex flex-wrap justify-content-between gap-3'>
            {userInfo.items && (
              <>
                <div>
                  <h5>Sangha Name : {userInfo.items.name}</h5>
                  <p>Phone : {userInfo.items.phone}</p>
                  <p>email : {userInfo.items.email}</p>
                  <p>DOB : {userInfo.items.dateOfBirth.split('T')[0]}</p>
                  <p>Blood Group : {userInfo.items.bloodGroup}</p>
                  <p>Aadhar: {userInfo.items.aadhar}</p>

                  <div className='d-flex flex-wrap gap-3'>
                    <p>Address : </p>
                    <p style={{ whiteSpace: 'pre-line' }}>
                      {userInfo.items.address}
                    </p>
                  </div>
                  <h5>
                    Bank Name : {userInfo.items.bankName} ,{' '}
                    {userInfo.items.branchName}
                  </h5>
                  <p>ISFC : {userInfo.items.ifscCode}</p>

                  <p> Account : {userInfo.items.bankAccNumber}</p>
                </div>
                <div>
                  <img
                    src={userInfo.items.photo || img}
                    alt={'Employee Img'}
                    style={{ height: '250px' }}
                    className='mb-5'
                  />
                </div>
              </>
            )}
          </div>
          <div className='mb-3'>
            <Button onClick={() => setChangePassShow(true)}>
              Update Password
            </Button>
            <UpdatePassword
              show={changePassShow}
              onHide={() => setChangePassShow(false)}
            />
          </div>

          <hr />
          <div
            className='d-flex flex-wrap gap-3 justify-content-between align-items-end cursor-pointer mx-3'
            onClick={() => setShowRepayment(!showRepayment)}
          >
            <div className='d-flex gap-4 flex-wrap'>
              <h5 className='m-0'> Incentive Details</h5>
            </div>
            <p
              className='m-0 cursor-pointer small'
              onClick={() => setShowRepayment(!showRepayment)}
            >
              {showRepayment ? 'Show Less' : 'Show more'}
            </p>
          </div>
          <Collapse in={showRepayment}>
            <div className='total-container'>
              <div className='d-flex justify-content-center flex-wrap'>
                {userInfo.allIncentivesOfEmployee &&
                  userInfo.allIncentivesOfEmployee.map((ele) => {
                    return (
                      <div
                        className='col-12 col-md-4 col-lg-3 card-container'
                        key={ele._id}
                      >
                        <Card className='p-3 h-100'>
                          {(ele.SanghaData && (
                            <Link to={`/sangha-list/${ele.SanghaData._id}`}>
                              <h5 className='text-center'>
                                {ele.SanghaData.name}
                              </h5>
                            </Link>
                          )) ||
                            (ele.ReceiptData && (
                              <Link to={`/sangha-list/${ele.ReceiptData._id}`}>
                                <h5 className='text-center'>
                                  {ele.ReceiptData.sanghaId &&
                                    ele.ReceiptData.sanghaId.name}
                                </h5>
                              </Link>
                            ))}

                          <hr />
                          <h6 className='text-center'>
                            {ele.incentiveType === 'RR' ? 'Repayment' : 'Loan'}{' '}
                            amount : {ele.loanOrRepaymentAmount}
                          </h6>
                          <h6 className='text-center'>
                            Receipt Number : 12345781245
                          </h6>
                          {(ele.SanghaData && (
                            <p className='text-center'>
                              Bank :{' '}
                              {ele.SanghaData.bankName &&
                                ele.SanghaData.bankName.name +
                                  ' , ' +
                                  ele.SanghaData.bankName.branchName}
                            </p>
                          )) ||
                            (ele.ReceiptData && (
                              <p className='text-center'>
                                Bank :{' '}
                                {ele.ReceiptData.bankId &&
                                  ele.ReceiptData.bankId.name +
                                    ' , ' +
                                    ele.ReceiptData.bankId.branchName}
                              </p>
                            ))}

                          <p className='text-center'>
                            Receipt Date :{' '}
                            {ele.incentiveGeneratedDate &&
                              ele.incentiveGeneratedDate.split('T')[0]}
                          </p>
                          <p className='text-center'>
                            Paid Date :{' '}
                            {ele.incentiveGroupId
                              ? ele.incentiveGroupId.incentivePaidDate.split(
                                  'T'
                                )[0]
                              : 'NA'}
                          </p>
                        </Card>
                      </div>
                    )
                  })}
              </div>
              <div className='d-flex justify-content-end me-3'>
                <Paginate
                  type={'incentive'}
                  page={userInfo.empincentiveCurrentPage}
                  pages={userInfo.empincentiveTotalPages}
                />
              </div>
            </div>
          </Collapse>
          <hr />
          <div
            className='d-flex flex-wrap gap-3 justify-content-between align-items-end cursor-pointer mx-3'
            onClick={() => setShowSangha(!showSangha)}
          >
            <div className='d-flex gap-4 flex-wrap'>
              <h5 className='m-0'>Paid Incentive Detail</h5>
            </div>
            <p
              className='m-0 cursor-pointer small'
              onClick={() => setShowSangha(!showSangha)}
            >
              {showSangha ? 'Show Less' : 'Show more'}
            </p>
          </div>
          <Collapse in={showSangha}>
            <div className='total-container'>
              <div className='d-flex justify-content-center flex-wrap'>
                {userInfo.items &&
                  userInfo.items.incentiveData &&
                  userInfo.items.incentiveData.map((ele) => {
                    return (
                      <div
                        className='col-12 col-md-4 col-lg-3 card-container'
                        key={ele._id}
                      >
                        <Card className='p-3 h-100'>
                          <h6 className='text-center'>
                            Total{' '}
                            {ele.incentiveType === 'SC' ? 'Loan' : 'Repayment'}{' '}
                            amount : {ele.totalLoanOrRepaymentAmount}
                          </h6>
                          <h6 className='text-center'>
                            Incentive amount : {ele.incentiveAmount}
                          </h6>
                          <p className='text-center'>
                            Incentive Type :{' '}
                            {ele.incentiveType === 'SC'
                              ? 'Sangha Creation'
                              : 'Repayment Receipt'}
                          </p>
                          <p className='text-center'>
                            {ele.incentiveType === 'SC'
                              ? 'Sanghas'
                              : 'Receipts'}{' '}
                            Created : {ele.totalSanghaIdOrReceiptId}
                          </p>

                          <p className='text-center'>
                            Payment Date Date :{' '}
                            {ele.incentivePaidDate.split('T')[0]}
                          </p>
                        </Card>
                      </div>
                    )
                  })}
              </div>
              <div className='d-flex justify-content-end me-3'>
                <Paginate
                  type='paidIncentive'
                  page={userInfo.incentiveCurrentPage}
                  pages={userInfo.incentiveTotalPages}
                />
              </div>
            </div>
          </Collapse>
          <hr />
        </>
      )}
    </div>
  )
}

function UpdatePassword(props) {
  const [data, setData] = useState({ newPassword: '', reEnteredPassword: '' })
  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }
  const [error, setError] = useState(false)
  const dispatch = useDispatch()
  const onSubmit = (e) => {
    e.preventDefault()
    var temp = false
    for (const key in data) {
      if (!data[key]) {
        temp = true
        break
      }
    }
    if (data.newPassword !== data.reEnteredPassword) temp = true

    if (!temp) {
      dispatch(updatePass({ data }))
      props.onHide()

      setData({
        password: '',
        repassword: '',
      })
    }
    setError(temp)
  }
  return (
    <Modal
      {...props}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          Update Password
        </Modal.Title>
      </Modal.Header>
      <Form>
        <Modal.Body>
          <Form.Group className='mb-3 w-100'>
            <Form.Label>Enter New Password</Form.Label>
            <Form.Control
              className={`w-100 ${error && !data.newPassword && 'is-invalid'}`}
              type='password'
              placeholder='Enter Password'
              required
              name='newPassword'
              value={data.newPassword}
              onChange={onChange}
            />
            <div className='invalid-feedback'>This Field is required</div>
          </Form.Group>
          <Form.Group className='mb-3 w-100'>
            <Form.Label>Re-enter New Password</Form.Label>
            <Form.Control
              className={`w-100 ${
                error &&
                (!data.reEnteredPassword ||
                  data.reEnteredPassword !== data.password) &&
                'is-invalid'
              }`}
              type='password'
              placeholder='Re-Enter Password'
              required
              name='reEnteredPassword'
              value={data.reEnteredPassword}
              onChange={onChange}
            />
            <div className='invalid-feedback'>{`${
              !data.reEnteredPassword
                ? 'This Field is required'
                : data.reEnteredPassword !== data.password &&
                  'Password doesnot match'
            }`}</div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onSubmit} type='submit'>
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default UserProfile
