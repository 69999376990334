import React from 'react'

const PrintDuepayment = React.forwardRef(({ duepayment, filter }, ref) => {
  const getDate = (date) => {
    const DueDate = new Date(date)

    var dd = String(DueDate.getDate()).padStart(2, '0')
    var mm = String(DueDate.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = DueDate.getFullYear()
    return dd + '/' + mm + '/' + yyyy
  }

  return (
    <div ref={ref} className='m-5'>
      <h5 className='m-3 text-center'>Duepayment report</h5>
      <h6 className='m-3 text-center'>filters : {filter}</h6>
      <table className='table table-bordered'>
        <thead>
          <tr>
            <th scope='col'>#</th>
            <th scope='col'>SHG NAME</th>
            <th scope='col'>SANGHA TYPE</th>
            <th scope='col'>ADDRESS</th>
            <th scope='col'>TOTAL NO OF MEMBERS </th>

            <th scope='col'>FIELD OFFICER NAME</th>
            <th scope='col'>BANK NAME</th>
            <th scope='col'>BRANCH NAME</th>

            <th scope='col'>LOAN DATE</th>
            <th scope='col'>LOAN AMOUNT</th>
            <th scope='col'>REPAYMENT START DATE</th>
            <th scope='col'>S B A/C NUMBER</th>
            <th scope='col'>LOAN A/C NUMBER</th>
            <th scope='col'>REPAYMENT AMONT</th>
            <th scope='col'>REPAYMENT DATE</th>
            <th scope='col'>AMOUNT PAID UPTO THE BEGINNING</th>
            <th scope='col'>TOTAL DUE AMOUNT</th>
            <th scope='col'>NO OF DAYS DUE</th>
            <th scope='col'>STATUS</th>
            <th scope='col'>REMARKS</th>
          </tr>
        </thead>
        <tbody>
          {duepayment.map((demand, id) => {
            return (
              <tr key={demand._id}>
                <th scope='row'>{id + 1}</th>
                <td>{demand.name}</td>
                <td>{demand.type}</td>
                <td>{demand.address}</td>
                <td>{demand.memberCount}</td>

                <td>{demand.EmployeeData[0] && demand.EmployeeData[0].name}</td>
                <td>{demand.BankData[0] && demand.BankData[0].name}</td>
                <td>{demand.BankData[0] && demand.BankData[0].branchName}</td>

                <td>{getDate(demand.loanDate)}</td>
                <td>{demand.loanAmount}</td>
                <td>{getDate(demand.repaymentStartDate)}</td>
                <td>{demand.savingsAccountNumber}</td>
                <td>{demand.loanAccountNumber}</td>
                <td>{demand.repaymentAmount}</td>
                <td>{demand.repaymentFixedDate}</td>
                <td>{demand.AmountPaidUptoTheBegining}</td>
                <td>{demand.DueAmt}</td>
                <td>{demand.NoOfDaysDue}</td>
                <td>{demand.Status}</td>
                <td>{demand.Remarks}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
})

export default PrintDuepayment
