import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from './axios/axios'
import { logout } from './userSlice'

const initialState = {
  employeeList: [],
  employee: {},
  error: null,
  isLoading: false,
  currentPage: 0,
  totalPages: 0,
  addSuccess: false,
  updateSuccess: false,
  addIncetive: false,
  updatePassSuccess: false,
}

export const getEmployeeList = createAsyncThunk(
  'employee/getEmployeeList',
  async (obj, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    try {
      const { user } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.user.token}`,
        },
      }
      const res = await axios.get(`/api/employee?${obj}`, config)

      return fulfillWithValue(res.data)
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        dispatch(logout(error.response.data))
      }
      return rejectWithValue(error.response.data)
    }
  }
)
export const getOneEmployee = createAsyncThunk(
  'employee/getOneEmployee',
  async (
    { id, params },
    { rejectWithValue, fulfillWithValue, getState, dispatch }
  ) => {
    try {
      const { user } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.user.token}`,
        },
      }
      const res = await axios.get(`/api/employee/${id}?${params}`, config)

      return fulfillWithValue(res.data)
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        dispatch(logout(error.response.data))
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const addEmployee = createAsyncThunk(
  'employee/addEmployee',
  async (obj, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    try {
      const { user } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.user.token}`,
        },
      }
      const res = await axios.post(`/api/employee`, { ...obj }, config)

      return fulfillWithValue(res.data)
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        dispatch(logout(error.response.data))
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateEmployee = createAsyncThunk(
  'employee/updateEmployee',
  async (
    { id, data },
    { rejectWithValue, fulfillWithValue, getState, dispatch }
  ) => {
    try {
      const { user } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.user.token}`,
        },
      }
      const res = await axios.put(`/api/employee/${id}`, { ...data }, config)

      return fulfillWithValue(res.data)
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        dispatch(logout(error.response.data))
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateEmployeePass = createAsyncThunk(
  'employee/updateEmployeePass',
  async (
    { id, data },
    { rejectWithValue, fulfillWithValue, getState, dispatch }
  ) => {
    try {
      const { user } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.user.token}`,
        },
      }
      const res = await axios.put(
        `/api/employee/${id}/updatePassword`,
        { ...data },
        config
      )

      return fulfillWithValue(res.data)
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        dispatch(logout(error.response.data))
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const payIncentive = createAsyncThunk(
  'employee/payIncentive',
  async (
    { id, data },
    { rejectWithValue, fulfillWithValue, getState, dispatch }
  ) => {
    try {
      const { user } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.user.token}`,
        },
      }
      const res = await axios.post(
        `/api/employee/${id}/incentive`,
        { ...data },
        config
      )

      return fulfillWithValue(res.data)
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        dispatch(logout(error.response.data))
      }
      return rejectWithValue(error.response.data)
    }
  }
)

const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    resetState: (state) => {
      state.employee = {}
      state.error = ''
      state.addSuccess = false
      state.updateSuccess = false
      state.addIncetive = false
      state.updatePassSuccess = false
    },
  },
  extraReducers: {
    [getEmployeeList.pending]: (state) => {
      state.isLoading = true
      state.error = ''
    },
    [getEmployeeList.fulfilled]: (state, action) => {
      state.isLoading = false
      state.employeeList = action.payload.items
      state.currentPage = action.payload.currentPage
      state.totalPages = action.payload.totalPages
    },
    [getEmployeeList.rejected]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    [getOneEmployee.pending]: (state) => {
      state.isLoading = true
      state.error = ''
    },
    [getOneEmployee.fulfilled]: (state, action) => {
      state.isLoading = false
      state.employee = action.payload
    },
    [getOneEmployee.rejected]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    [addEmployee.pending]: (state) => {
      state.isLoading = true
      state.error = ''
    },
    [addEmployee.fulfilled]: (state, action) => {
      state.isLoading = false
      state.addSuccess = true
    },
    [addEmployee.rejected]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    [updateEmployee.pending]: (state) => {
      state.isLoading = true
      state.error = ''
    },
    [updateEmployee.fulfilled]: (state, action) => {
      state.isLoading = false
      state.updateSuccess = true
    },
    [updateEmployee.rejected]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    [payIncentive.pending]: (state) => {
      state.isLoading = true
      state.error = ''
    },
    [payIncentive.fulfilled]: (state, action) => {
      state.isLoading = false
      state.addIncetive = true
    },
    [payIncentive.rejected]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    [updateEmployeePass.pending]: (state) => {
      state.isLoading = true
      state.error = ''
    },
    [updateEmployeePass.fulfilled]: (state, action) => {
      state.isLoading = false
      state.updatePassSuccess = true
    },
    [updateEmployeePass.rejected]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export const { resetState } = employeeSlice.actions

export default employeeSlice.reducer
