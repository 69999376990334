import React, { createRef, useEffect } from 'react'
import { useState } from 'react'
import { Alert, Button, Card, Form, FormCheck, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import ReactToPrint from 'react-to-print'
import Loader from '../components/Loader'
import Paginate from '../components/Paginate'
import PrintLendingReoprt from '../components/prints/PrintLendingReport'

import { getLendingList, getLendingPrintList } from '../features/lendingSlice'
import { resetState } from '../features/sanghaSlice'

const LendingReport = () => {
  const [filter, setFilter] = useState({})
  const [viewTable, setViewTable] = useState(false)

  const navigate = useNavigate()
  const componentRef = createRef()
  const { user } = useSelector((state) => state.user)

  const [searchParams, setSearchParams] = useSearchParams()

  const {
    lendingList,
    currentPage,
    totalPages,
    bankData,
    employeeData,
    isLoading,
    lendingPrintList,
    filter: searchFilter,
    error,
  } = useSelector((state) => state.lending)

  const onChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value })
  }
  const params = searchParams.toString()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getLendingList(params))
  }, [dispatch, params])

  const onSubmit = (e) => {
    e.preventDefault()
    setSearchParams(filter)
  }
  return (
    <div className='total-container'>
      <div className='d-flex align-items-center'>
        <Button
          variant='light'
          onClick={() => navigate(-1)}
          className='mx-3 mb-3 px-4'
        >
          {'🡐 Back'}
        </Button>
        <p>Lending Report list</p>
      </div>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Alert variant='danger'>{error}</Alert>
      ) : (
        <>
          <div>
            <Form>
              <div className='d-flex flex-column flex-lg-row gap-5 '>
                <div className='d-flex flex-column flex-md-row gap-5 justify-content-around flex-grow-1 align-items-md-end'>
                  <Form.Group className='w-100'>
                    <Form.Select
                      onChange={onChange}
                      name='type'
                      value={filter.type}
                    >
                      <option value={''}>Select Search Type</option>
                      <option value='sangha'>Sangha</option>

                      <option value='bank'>Bank & Field Officer</option>
                    </Form.Select>
                  </Form.Group>
                  {filter.type === 'sangha' && (
                    <Form.Group className='w-100'>
                      <Form.Control
                        name='keyword'
                        type='text'
                        placeholder='Search Here'
                        onChange={onChange}
                        value={filter.keyword}
                      />
                    </Form.Group>
                  )}
                </div>
                <div className='d-flex flex-column flex-md-row gap-5 justify-content-around flex-grow-1 align-items-md-end'>
                  <Form.Group className='w-100'>
                    <Form.Label>From</Form.Label>
                    <Form.Control
                      name='fromDate'
                      type='date'
                      onChange={onChange}
                      value={filter.fromDate}
                    />
                  </Form.Group>
                  <Form.Group className='w-100'>
                    <Form.Label>To</Form.Label>
                    <Form.Control
                      name='toDate'
                      type='date'
                      onChange={onChange}
                      value={filter.toDate}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className='d-flex flex-column flex-md-row gap-5  flex-grow-1 align-items-md-end mt-4'>
                {filter.type === 'bank' && (
                  <>
                    <Form.Group className='w-100'>
                      <Form.Select
                        name='bankName'
                        onChange={onChange}
                        value={filter.bankName}
                      >
                        <option value=''>Select Bank</option>
                        {[
                          ...new Set(
                            bankData.map((bank) => {
                              return bank.name
                            })
                          ),
                        ].map((ele) => (
                          <option value={ele}>{ele}</option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className='w-100'>
                      <Form.Select
                        name='branchName'
                        onChange={onChange}
                        value={filter.branchName}
                      >
                        <option value=''>Select Branch</option>
                        {bankData
                          .filter((bank) => bank.name === filter.bankName)
                          .map((bank) => {
                            return (
                              <option value={bank.branchName}>
                                {bank.branchName}
                              </option>
                            )
                          })}
                      </Form.Select>
                    </Form.Group>
                    {(user.type === 'Admin' || user.type === 'Manager') && (
                      <Form.Group className='w-100'>
                        <Form.Label></Form.Label>
                        <Form.Select
                          name='keyword'
                          onChange={onChange}
                          value={filter.keyword}
                        >
                          <option>Select Field Officer</option>
                          {employeeData.map((employee) => {
                            return (
                              <option value={employee._id}>
                                {employee.name}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </Form.Group>
                    )}
                  </>
                )}
              </div>
              <div>
                <Form.Group style={{ maxWidth: '18rem' }} className='mt-5'>
                  <Form.Select onChange={onChange} name='loanClose'>
                    <option value=''>Select Loan Status</option>
                    <option value='ongoing'>ongoing</option>
                    <option value='closed'>closed</option>
                    <option value='all'>all</option>
                  </Form.Select>
                </Form.Group>
              </div>
              <div className='d-flex justify-content-center'>
                <Button
                  variant='primary'
                  className='mt-3 px-5'
                  onClick={onSubmit}
                >
                  Search
                </Button>
              </div>
            </Form>
          </div>
          <hr />
          <div className='d-flex gap-5 justify-content-between px-3'>
            <FormCheck
              onChange={(e) => setViewTable(e.target.checked)}
              checked={viewTable}
              type='switch'
              id='custom-switch'
              label='Switch to Table'
            />
            {(user.type === 'Admin' || user.type === 'Manager') && (
              <>
                <ReactToPrint
                  trigger={() => <Button className='px-4'>Print</Button>}
                  content={() => componentRef.current}
                  onBeforeGetContent={() => {
                    return dispatch(getLendingPrintList(params))
                  }}
                />
              </>
            )}
          </div>
          {lendingList && lendingList.length ? (
            <>
              {viewTable ? (
                <>
                  <Table responsive striped bordered hover className='mt-4'>
                    <thead>
                      <tr>
                        <th>Sangha Name</th>
                        <th>Loan Amount</th>
                        <th>Members</th>
                        <th>Bank Name</th>
                        <th>Branch Name</th>
                        <th>Loan Account Number</th>
                        <th>FieldOfficer</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {lendingList &&
                        lendingList.map((sangha) => {
                          return (
                            <tr key={sangha._id}>
                              <td className='text-center'>{sangha.name}</td>
                              <td className='text-center'>
                                {sangha.loanAmount}
                              </td>
                              <td className='text-center'>
                                {sangha.memberCount}
                              </td>
                              <td className='text-center'>
                                {sangha.BankData[0].name}
                              </td>
                              <td className='text-center'>
                                {sangha.BankData[0].branchName}
                              </td>
                              <td className='text-center'>
                                {sangha.loanAccountNumber}
                              </td>
                              <td className='text-center'>
                                {sangha.EmployeeData[0].name}
                              </td>
                              <td>
                                <Link to={sangha._id} className='w-100 mt-auto'>
                                  <Button
                                    className='w-100 '
                                    onClick={() => dispatch(resetState())}
                                  >
                                    View More
                                  </Button>
                                </Link>
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </Table>
                </>
              ) : (
                <>
                  <div className='d-flex justify-content-center flex-wrap'>
                    {lendingList &&
                      lendingList.map((lending) => {
                        return (
                          <div
                            className='col-12 col-md-4 col-lg-3 card-container'
                            key={lending._id}
                          >
                            <Card className='p-3 h-100'>
                              <h5 className='text-center'>{lending.name}</h5>
                              <hr />
                              <h6 className='text-center'>
                                Loan Amount : {lending.loanAmount}
                              </h6>
                              <p className='text-center'>
                                Members: {lending.memberCount}
                              </p>
                              <p className='text-center'>
                                Bank Name: {lending.BankData[0].name}
                              </p>
                              <p className='text-center'>
                                Branch Name: {lending.BankData[0].branchName}
                              </p>
                              <p className='text-center'>
                                Loan Account Number: {lending.loanAccountNumber}
                              </p>
                              <p className='text-center'>
                                FieldOfficer : {lending.EmployeeData[0].name}
                              </p>
                              <Link
                                to={`/sangha-list/${lending._id}`}
                                className='w-100 mt-auto'
                              >
                                <Button
                                  className='w-100 '
                                  onClick={() => dispatch(resetState())}
                                >
                                  View More
                                </Button>
                              </Link>
                            </Card>
                          </div>
                        )
                      })}
                  </div>
                </>
              )}

              <div className='d-flex justify-content-end me-3'>
                <Paginate page={currentPage} pages={totalPages} />
              </div>
            </>
          ) : (
            <>
              <h4 className='text-center'>No Data Found</h4>
            </>
          )}
        </>
      )}
      <div style={{ display: 'none' }}>
        <PrintLendingReoprt
          ref={componentRef}
          lending={lendingPrintList}
          filter={searchFilter}
        />
      </div>
    </div>
  )
}

export default LendingReport
