import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from './axios/axios'
import { logout } from './userSlice'

const initialState = {
  sanghaList: [],
  sangha: {},
  error: null,
  isLoading: false,
  currentPage: 0,
  totalPages: 0,
  employeeData: [],
  bankData: [],
  renewRisk: false,
  receiptPaid: false,
  claimRisk: false,
  closeLoan: false,
  receiptGen: false,
  receiptUpdate: false,
}

export const getSanghaList = createAsyncThunk(
  'sangha/getSanghaList',
  async (obj, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    try {
      const { user } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.user.token}`,
        },
      }
      const res = await axios.get(`/api/sangha?${obj}`, config)

      return fulfillWithValue(res.data)
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        dispatch(logout(error.response.data))
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const renewRiskFund = createAsyncThunk(
  'sangha/renewRiskFund',
  async (
    { id, data },
    { rejectWithValue, fulfillWithValue, getState, dispatch }
  ) => {
    try {
      const { user } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.user.token}`,
        },
      }
      const res = await axios.put(
        `/api/sangha/${id}/renewrisk`,
        { ...data },
        config
      )

      return fulfillWithValue(res.data)
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        dispatch(logout(error.response.data))
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const claimRiskFund = createAsyncThunk(
  'sangha/claimRiskFund',
  async (
    { id, data },
    { rejectWithValue, fulfillWithValue, getState, dispatch }
  ) => {
    try {
      const { user } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.user.token}`,
        },
      }
      const res = await axios.put(
        `/api/sangha/${id}/reclaimrisk`,
        { ...data },
        config
      )

      return fulfillWithValue(res.data)
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        dispatch(logout(error.response.data))
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const payReceipt = createAsyncThunk(
  'sangha/payReceipt',
  async (
    { id, data },
    { rejectWithValue, fulfillWithValue, getState, dispatch }
  ) => {
    try {
      const { user } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.user.token}`,
        },
      }
      const res = await axios.put(`/api/receipt/${id}`, { ...data }, config)

      return fulfillWithValue(res.data)
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        dispatch(logout(error.response.data))
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const generateReceipt = createAsyncThunk(
  'sangha/generateReceipt',
  async (
    { data },
    { rejectWithValue, fulfillWithValue, getState, dispatch }
  ) => {
    try {
      const { user } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.user.token}`,
        },
      }
      const res = await axios.post(`/api/receipt`, { ...data }, config)

      return fulfillWithValue(res.data)
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        dispatch(logout(error.response.data))
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateReceipt = createAsyncThunk(
  'sangha/updateReceipt',
  async (
    { id, data },
    { rejectWithValue, fulfillWithValue, getState, dispatch }
  ) => {
    try {
      const { user } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.user.token}`,
        },
      }
      const res = await axios.put(
        `/api/receipt/edit/${id}`,
        { ...data },
        config
      )

      return fulfillWithValue(res.data)
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        dispatch(logout(error.response.data))
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const getOneSangha = createAsyncThunk(
  'bank/getOneSangha',
  async (
    { id, params },
    { rejectWithValue, fulfillWithValue, getState, dispatch }
  ) => {
    try {
      const { user } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.user.token}`,
        },
      }
      const res = await axios.get(`/api/sangha/${id}?${params}`, config)

      const items = {
        aliveMembers: res.data.AliveMembers,
        deadMembers: res.data.DeadMembers,
        riskFundData: res.data.RiskFundData,
        representatives: res.data.Representatives,
        ...res.data.items,
      }

      return fulfillWithValue({
        items,
        currentRepaymentPage: res.data.currentRepaymentPage,
        currentSavingPage: res.data.currentSavingPage,
        totalRepaymentPages: res.data.totalRepaymentPages,
        totalSavingPages: res.data.totalSavingPages,
      })
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        dispatch(logout(error.response.data))
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const addSangha = createAsyncThunk(
  'bank/addSangha',
  async (obj, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    try {
      const { user } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.user.token}`,
        },
      }
      const res = await axios.post(`/api/sangha`, { ...obj }, config)

      return fulfillWithValue(res.data)
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        dispatch(logout(error.response.data))
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const closeLoan = createAsyncThunk(
  'bank/closeLoan',
  async (
    { id, data },
    { rejectWithValue, fulfillWithValue, getState, dispatch }
  ) => {
    try {
      const { user } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.user.token}`,
        },
      }
      const res = await axios.put(
        `/api/sangha/${id}/loanclose`,
        { ...data },
        config
      )

      return fulfillWithValue(res.data)
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        dispatch(logout(error.response.data))
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateSangha = createAsyncThunk(
  'bank/updateSangha',
  async (
    { id, data },
    { rejectWithValue, fulfillWithValue, getState, dispatch }
  ) => {
    try {
      const { user } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.user.token}`,
        },
      }
      const res = await axios.put(`/api/sangha/${id}`, { ...data }, config)

      return fulfillWithValue(res.data)
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        dispatch(logout(error.response.data))
      }
      return rejectWithValue(error.response.data)
    }
  }
)

const sanghaSlice = createSlice({
  name: 'sangha',
  initialState,
  reducers: {
    resetState: (state) => {
      state.sangha = {}
      state.error = ''
      state.addSuccess = false
      state.updateSuccess = false
      state.renewRisk = false
      state.receiptPaid = false
      state.claimRisk = false
      state.closeLoan = false
      state.receiptGen = false
      state.receiptUpdate = false
    },
  },
  extraReducers: {
    [getSanghaList.pending]: (state) => {
      state.isLoading = true
      state.error = ''
    },
    [getSanghaList.fulfilled]: (state, action) => {
      state.isLoading = false
      state.sanghaList = action.payload.items
      state.currentPage = action.payload.currentPage
      state.totalPages = action.payload.totalPages
      state.employeeData = action.payload.EmployeeData
      state.bankData = action.payload.BankData
      state.error = ''
    },
    [getSanghaList.rejected]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    [getOneSangha.pending]: (state) => {
      state.isLoading = true
      state.error = ''
    },
    [getOneSangha.fulfilled]: (state, action) => {
      state.isLoading = false
      state.sangha = action.payload
      state.error = ''
    },
    [getOneSangha.rejected]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    [addSangha.pending]: (state) => {
      state.isLoading = true
      state.addSuccess = false
      state.error = ''
    },
    [addSangha.fulfilled]: (state, action) => {
      state.isLoading = false
      state.addSuccess = true
      state.error = ''
    },
    [addSangha.rejected]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
      state.addSuccess = false
    },
    [updateSangha.pending]: (state) => {
      state.isLoading = true
      state.updateSuccess = false
      state.error = ''
    },
    [updateSangha.fulfilled]: (state, action) => {
      state.isLoading = false
      state.updateSuccess = true
      state.error = ''
    },
    [updateSangha.rejected]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
      state.updateSuccess = false
    },
    [renewRiskFund.pending]: (state) => {
      state.isLoading = true
      state.renewRisk = false
      state.error = ''
    },
    [renewRiskFund.fulfilled]: (state, action) => {
      state.isLoading = false
      state.renewRisk = true
      state.error = ''
    },
    [renewRiskFund.rejected]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
      state.renewRisk = false
    },
    [payReceipt.pending]: (state) => {
      state.isLoading = true
      state.receiptPaid = false
      state.error = ''
    },
    [payReceipt.fulfilled]: (state, action) => {
      state.isLoading = false
      state.receiptPaid = true
      state.error = ''
    },
    [payReceipt.rejected]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
      state.receiptPaid = false
    },
    [claimRiskFund.pending]: (state) => {
      state.isLoading = true
      state.claimRisk = false
      state.error = ''
    },
    [claimRiskFund.fulfilled]: (state, action) => {
      state.isLoading = false
      state.claimRisk = true
      state.error = ''
    },
    [claimRiskFund.rejected]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
      state.claimRisk = false
    },
    [closeLoan.pending]: (state) => {
      state.isLoading = true
      state.closeLoan = false
      state.error = ''
    },
    [closeLoan.fulfilled]: (state, action) => {
      state.isLoading = false
      state.closeLoan = true
      state.error = ''
    },
    [closeLoan.rejected]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
      state.closeLoan = false
    },
    [generateReceipt.pending]: (state) => {
      state.isLoading = true
      state.receiptGen = false
      state.error = ''
    },
    [generateReceipt.fulfilled]: (state, action) => {
      state.isLoading = false
      state.receiptGen = true
      state.error = ''
    },
    [generateReceipt.rejected]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
      state.receiptGen = false
    },
    [updateReceipt.pending]: (state) => {
      state.isLoading = true
      state.receiptUpdate = false
      state.error = ''
    },
    [updateReceipt.fulfilled]: (state, action) => {
      state.isLoading = false
      state.receiptUpdate = true
      state.error = ''
    },
    [updateReceipt.rejected]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
      state.receiptUpdate = false
    },
  },
})
export const { resetState } = sanghaSlice.actions

export default sanghaSlice.reducer
