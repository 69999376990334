import React, { useEffect } from 'react'
import { useState } from 'react'
import { Button, Card, Collapse } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import { Link, useNavigate } from 'react-router-dom'
import Loader from '../components/Loader'
import Paginate from '../components/Paginate'
import { getOneBank, resetState } from '../features/bankSlice'
import { resetState as resetSanghaState } from '../features/sanghaSlice'
import { resetState as resetEmployeeState } from '../features/employeeSlice'

const BankProfile = () => {
  const [showLendings, setShowLendings] = useState(false)
  const [showReceipts, setShowReceipts] = useState(false)
  const navigate = useNavigate()

  const getDate = (date) => {
    const dob = new Date(date)

    var dd = String(dob.getDate()).padStart(2, '0')
    var mm = String(dob.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = dob.getFullYear()
    return mm + '/' + dd + '/' + yyyy
  }
  const [searchParams, setSearchParams] = useSearchParams()
  const params = searchParams.toString()

  const { isLoading, error, bank } = useSelector((state) => state.bank)

  const { id } = useParams()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getOneBank({ id, params }))
  }, [dispatch, id, params])
  return (
    <div className='total-container'>
      <div className='d-flex align-items-center'>
        <Button
          variant='light'
          onClick={() => navigate(-1)}
          className='mx-3 mb-3 px-4'
        >
          {'🡐 Back'}
        </Button>
        <p>Bank Profile</p>
      </div>
      <div className='mx-3 d-flex flex-wrap gap-3 justify-content-between'>
        <h4>Bank Details</h4>
      </div>
      <hr />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className='mx-3 d-flex flex-wrap justify-content-between gap-5'>
            <div>
              <h5>
                Bank Name : {bank.name} , {bank.branchName}
              </h5>
              <p>ISFC Code : {bank.branchCode}</p>
              <p>Manager : {bank.manager}</p>
              <p>Manager contact : {bank.managerContact}</p>
              <p>Branch Tel : {bank.branchTelNumber}</p>
              <p> Email : {bank.email}</p>
              <div className='d-flex flex-wrap gap-3'>
                <p>Address : </p>
                <p style={{ whiteSpace: 'pre-line' }}>{bank.address}</p>
              </div>
            </div>
            <div>
              <Link to='edit'>
                <Button onClick={() => dispatch(resetSanghaState())}>
                  Edit Bank Details
                </Button>
              </Link>
            </div>
          </div>

          <hr />

          <div
            className='d-flex flex-wrap gap-3 justify-content-between align-items-end cursor-pointer mx-3'
            onClick={() => setShowLendings(!showLendings)}
          >
            <div className='d-flex gap-4 flex-wrap'>
              <h5 className='m-0'>Lending Details</h5>
              <h6 className='ms-3 fs-6'>
                Total Lendings : {bank.TotalLendAmount}
              </h6>
            </div>
            <p
              className='m-0 cursor-pointer small'
              onClick={() => setShowLendings(!showLendings)}
            >
              {showLendings ? 'Show Less' : 'Show more'}
            </p>
          </div>
          <Collapse in={showLendings}>
            <div className='total-container'>
              <div className='d-flex justify-content-center flex-wrap'>
                {bank.LendingData &&
                  bank.LendingData.map((lending) => {
                    return (
                      <div
                        className='col-12 col-md-4 col-lg-3 card-container'
                        key={lending._id}
                      >
                        <Card className='p-3 h-100'>
                          <h5 className='text-center'>{lending.name}</h5>
                          <hr />
                          <h6 className='text-center'>
                            Loan Amount : {lending.loanAmount}
                          </h6>
                          <p className='text-center'>
                            Members: {lending.memberCount}
                          </p>

                          <p className='text-center'>
                            Loan Account Number: {lending.loanAccountNumber}
                          </p>
                          <p className='text-center'>
                            FieldOfficer :{' '}
                            {lending.employeeData[0] &&
                              lending.employeeData[0].name}
                          </p>
                          <Link
                            to={`/sangha-list/${lending._id}`}
                            className='w-100'
                          >
                            <Button
                              className='w-100 '
                              onClick={() => dispatch(resetState())}
                            >
                              View More
                            </Button>
                          </Link>
                        </Card>
                      </div>
                    )
                  })}
              </div>
              <div className='d-flex justify-content-end me-3'>
                <Paginate
                  page={bank.totalLendPage}
                  pages={bank.totalPages}
                  type={'lending'}
                />
              </div>
            </div>
          </Collapse>
          <hr />
          <div
            className='d-flex flex-wrap gap-3 justify-content-between align-items-end cursor-pointer mx-3'
            onClick={() => setShowReceipts(!showReceipts)}
          >
            <div className='d-flex gap-4 flex-wrap'>
              <h5 className='m-0'>Bank Receipts Details</h5>
            </div>
            <p
              className='m-0 cursor-pointer small'
              onClick={() => setShowReceipts(!showReceipts)}
            >
              {showReceipts ? 'Show Less' : 'Show more'}
            </p>
          </div>
          <Collapse in={showReceipts}>
            <div className='total-container'>
              <div className='d-flex justify-content-center flex-wrap'>
                {bank.ReceiptData &&
                  bank.ReceiptData.map((ele) => {
                    return (
                      <div
                        className='col-12 col-md-4 col-lg-3 card-container'
                        key={ele._id}
                      >
                        <Card className='p-3 h-100'>
                          <h6 className='text-center'>
                            {ele.type === 'Loan' ? 'Repayment' : 'Savings'}{' '}
                            amount : {ele.amount}
                          </h6>
                          <h6 className='text-center'>
                            Receipt Number : {ele.receiptId}
                          </h6>

                          <p className='text-center'>
                            Receipt Date : {getDate(ele.receiptGenerationDate)}
                          </p>
                          <Link
                            to={`/employee-list/${
                              ele.employeeData[0] && ele.employeeData[0]._id
                            }`}
                          >
                            <p
                              className='text-center'
                              onClick={() => {
                                dispatch(resetEmployeeState())
                              }}
                            >
                              FieldOfficer :{' '}
                              {ele.employeeData[0] && ele.employeeData[0].name}
                            </p>
                          </Link>
                        </Card>
                      </div>
                    )
                  })}
              </div>
              <div className='d-flex justify-content-end me-3'>
                <Paginate
                  page={bank.currentReceiptPage}
                  pages={bank.totalReceiptPage}
                  type={'receipt'}
                />
              </div>
            </div>
          </Collapse>
          <hr />
        </>
      )}
    </div>
  )
}

export default BankProfile
