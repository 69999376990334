import { configureStore } from '@reduxjs/toolkit'
import sanghaReducer from './features/sanghaSlice'
import bankReducer from './features/bankSlice'
import employeeReducer from './features/employeeSlice'
import homeReducer from './features/homeSlice'
import lendingReducer from './features/lendingSlice'
import securitydepositReducer from './features/securityDepositSlice'
import riskfundReducer from './features/riskfundSlice'
import savingsReducer from './features/savingsSlice'
import userReducer from './features/userSlice'
import duepaymentReducer from './features/duepaymentSlice'
import demandlistReducer from './features/demandSlice'
import repaymentReducer from './features/repaymentSlice'
import incomeReducer from './features/incomeReportSlice'

export const store = configureStore({
  reducer: {
    user: userReducer,
    sangha: sanghaReducer,
    bank: bankReducer,
    employee: employeeReducer,
    home: homeReducer,
    lending: lendingReducer,
    securitydeposit: securitydepositReducer,
    riskfund: riskfundReducer,
    savings: savingsReducer,
    duepayment: duepaymentReducer,
    demandlist: demandlistReducer,
    repayment: repaymentReducer,
    income: incomeReducer,
  },
})
