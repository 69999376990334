import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from './axios/axios'

const initialState = {
  user: localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null,
  error: null,
  isLoading: false,
  userInfo: {},
  loginSuccess: false,
  updatePassSuccess: false,
}

export const login = createAsyncThunk(
  'user/login',
  async (obj, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const { data } = await axios.post(
        `/api/employee/login`,
        { ...obj },
        config
      )

      localStorage.setItem('userInfo', JSON.stringify(data))

      return fulfillWithValue(data)
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        dispatch(logout(error.response.data))
      }
      return rejectWithValue(error.response.data)
    }
  }
)
export const getUserProfile = createAsyncThunk(
  'user/profile',
  async (
    { params },
    { rejectWithValue, fulfillWithValue, getState, dispatch }
  ) => {
    try {
      const { user } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.user.token}`,
        },
      }
      const { data } = await axios.get(
        `/api/employee/profile?${params}`,
        config
      )

      return fulfillWithValue(data)
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        dispatch(logout(error.response.data))
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const updatePass = createAsyncThunk(
  'user/updatePass',
  async (
    { data },
    { rejectWithValue, fulfillWithValue, getState, dispatch }
  ) => {
    try {
      const { user } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.user.token}`,
        },
      }
      const res = await axios.put(`/api/employee/profile`, { ...data }, config)

      return fulfillWithValue(res.data)
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        dispatch(logout(error.response.data))
      }
      return rejectWithValue(error.response.data)
    }
  }
)

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state, action) => {
      localStorage.removeItem('userInfo')
      state.token = null
      state.userInfo = {}
      state.user = null
      state.updatePassSuccess = false
      state.error = action.payload
    },
  },
  extraReducers: {
    [login.pending]: (state) => {
      state.isLoading = true
      state.error = null
    },
    [login.fulfilled]: (state, action) => {
      state.isLoading = false
      state.user = action.payload
      state.error = null
    },
    [login.rejected]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    [getUserProfile.pending]: (state) => {
      state.isLoading = true
      state.error = null
    },
    [getUserProfile.fulfilled]: (state, action) => {
      state.isLoading = false
      state.userInfo = action.payload
      state.error = null
    },
    [getUserProfile.rejected]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    [updatePass.pending]: (state) => {
      state.isLoading = true
      state.error = null
    },
    [updatePass.fulfilled]: (state, action) => {
      state.isLoading = false
      state.updatePassSuccess = true
      state.error = null
    },
    [updatePass.rejected]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})
export const { logout } = userSlice.actions
export default userSlice.reducer
