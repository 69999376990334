import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from './axios/axios'
import { logout } from './userSlice'

const initialState = {
  repaymentList: [],
  bankData: [],
  employeeData: [],
  error: null,
  isLoading: false,
  currentPage: 0,
  totalPages: 0,
}

export const getRepaymentList = createAsyncThunk(
  'repayment/getRepaymentList',
  async (obj, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    try {
      const { user } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.user.token}`,
        },
      }
      const res = await axios.get(`/api/home/repayment?${obj}`, config)
      console.log(res.data)
      return fulfillWithValue(res.data)
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        dispatch(logout(error.response.data))
      }
      return rejectWithValue(error.response.data)
    }
  }
)

const repaymentSlice = createSlice({
  name: 'repayment',
  initialState,
  reducers: {
    resetState: (state) => {
      state.error = ''
      state.addSuccess = false
      state.updateSuccess = false
    },
  },
  extraReducers: {
    [getRepaymentList.pending]: (state) => {
      state.isLoading = true
      state.error = ''
    },
    [getRepaymentList.fulfilled]: (state, action) => {
      state.isLoading = false
      state.repaymentList = action.payload.items
      state.employeeData = action.payload.EmployeeData
      state.bankData = action.payload.BankData
      state.currentPage = action.payload.currentPage
      state.totalPages = action.payload.totalPages
    },
    [getRepaymentList.rejected]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export default repaymentSlice.reducer
