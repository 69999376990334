import { useState } from 'react'
import { Alert, Button, Card, Form, FormCheck, Table } from 'react-bootstrap'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import Paginate from '../components/Paginate'

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getBankList, resetState } from '../features/bankSlice'
import Loader from '../components/Loader'

const BankList = () => {
  const [filter, setFilter] = useState({})
  const [viewTable, setViewTable] = useState(false)

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()

  const { bankList, currentPage, totalPages, isLoading, bankData, error } =
    useSelector((state) => state.bank)

  const onChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value })
  }
  const params = searchParams.toString()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getBankList(params))
  }, [dispatch, params])

  const onSubmit = (e) => {
    e.preventDefault()
    setSearchParams(filter)
  }
  return (
    <div className='total-container'>
      <div className='d-flex align-items-center'>
        <Button
          variant='light'
          onClick={() => navigate(-1)}
          className='mx-3 mb-3 px-4'
        >
          {'🡐 Back'}
        </Button>
        <p>Bank List</p>
      </div>

      {isLoading ? (
        <Loader />
      ) : error ? (
        <Alert variant='danger'>{error}</Alert>
      ) : (
        <>
          {' '}
          <div>
            <Form>
              <div className='d-flex flex-column flex-lg-row gap-5 '>
                <div className='d-flex flex-column flex-md-row gap-5 justify-content-around flex-grow-1 align-items-md-end'>
                  <Form.Group className='w-100'>
                    <Form.Label>From</Form.Label>
                    <Form.Control
                      name='fromDate'
                      type='date'
                      onChange={onChange}
                      value={filter.fromDate}
                    />
                  </Form.Group>
                  <Form.Group className='w-100'>
                    <Form.Label>To</Form.Label>
                    <Form.Control
                      name='toDate'
                      type='date'
                      onChange={onChange}
                      value={filter.toDate}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className='d-flex flex-column flex-md-row gap-5  flex-grow-1 align-items-md-end mt-4'>
                <>
                  <Form.Group className='w-100'>
                    <Form.Select
                      name='bankName'
                      onChange={onChange}
                      value={filter.bankName}
                    >
                      <option value=''>Select Bank</option>
                      {[
                        ...new Set(
                          bankData.map((bank) => {
                            return bank.name
                          })
                        ),
                      ].map((ele) => (
                        <option value={ele}>{ele}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className='w-100'>
                    <Form.Select
                      name='branchName'
                      onChange={onChange}
                      value={filter.branchName}
                    >
                      <option value=''>Select Branch</option>
                      {bankData
                        .filter((bank) => bank.name === filter.bankName)
                        .map((bank) => {
                          return (
                            <option value={bank.branchName}>
                              {bank.branchName}
                            </option>
                          )
                        })}
                    </Form.Select>
                  </Form.Group>
                </>
              </div>
              <div className='d-flex justify-content-center'>
                <Button
                  variant='primary'
                  className='mt-3 px-5'
                  onClick={onSubmit}
                >
                  Search
                </Button>
              </div>
            </Form>
          </div>
          <hr />
          <div className='d-flex gap-5 justify-content-between px-3'>
            <Link to='/create-bank'>
              <Button variant='success' onClick={() => dispatch(resetState())}>
                Create Bank
              </Button>
            </Link>
            <FormCheck
              onChange={(e) => setViewTable(e.target.checked)}
              checked={viewTable}
              type='switch'
              id='custom-switch'
              label='Switch to Table'
            />
          </div>
          {bankList && bankList.length ? (
            <>
              {viewTable ? (
                <>
                  <Table responsive striped bordered hover className='mt-4'>
                    <thead>
                      <tr>
                        <th>Bank Name</th>
                        <th>Branch Name</th>
                        <th>Loan Amount</th>
                        <th>Sangha Number</th>
                        <th>Bank Manager</th>
                        <th>Bank Phone</th>
                        <th>Manager Phone</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {bankList &&
                        bankList.map((bank) => {
                          return (
                            <tr key={bank._id}>
                              <td>{bank.name}</td>
                              <td>{bank.branchName}</td>
                              <td>{bank.totalLoanAmount}</td>
                              <td>{bank.totalSangha}</td>

                              <td>{bank.manager}</td>
                              <td>{bank.branchTelNumber}</td>
                              <td>{bank.managerContact}</td>
                              <td>
                                <Link to={bank._id} className='w-100 mt-auto'>
                                  <Button className='w-100 mt-auto'>
                                    View More
                                  </Button>
                                </Link>
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </Table>
                </>
              ) : (
                <div className='d-flex justify-content-center flex-wrap'>
                  {bankList &&
                    bankList.map((bank) => {
                      return (
                        <div
                          className='col-12 col-md-4 col-lg-3 card-container'
                          key={bank._id}
                        >
                          <Card className='p-3 h-100'>
                            <h5 className='text-center'>{`${bank.name} , ${bank.branchName}`}</h5>
                            <hr />
                            <h6 className='text-center'>
                              Total Loan Amount : {bank.totalLoanAmount}
                            </h6>
                            <p className='text-center'>
                              Sangha Number: {bank.totalSangha}
                            </p>
                            <p className='text-center'>
                              Bank Manager : {bank.manager}
                            </p>
                            <p className='text-center'>
                              Bank Phone: {bank.branchTelNumber}
                            </p>
                            <p className='text-center'>
                              Manager Phone: {bank.managerContact}
                            </p>
                            <Link to={bank._id} className='w-100 mt-auto'>
                              <Button className='w-100 mt-auto'>
                                View More
                              </Button>
                            </Link>
                          </Card>
                        </div>
                      )
                    })}
                </div>
              )}
              <div className='d-flex justify-content-end me-3'>
                <Paginate page={currentPage} pages={totalPages} />
              </div>
            </>
          ) : (
            <>
              <h4 className='text-center'>No Data Found</h4>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default BankList
