import React from 'react'

const PrintIncomeReoprt = React.forwardRef(({ lending }, ref) => {
  const getDate = (date) => {
    const DueDate = new Date(date)

    var dd = String(DueDate.getDate()).padStart(2, '0')
    var mm = String(DueDate.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = DueDate.getFullYear()
    return dd + '/' + mm + '/' + yyyy
  }

  return (
    <div ref={ref} className='m-5'>
      <h5 className='m-3 text-center'>Income report</h5>
      <h6 className='m-3 text-center'>
        filters : {lending[0] && lending[0].Filter}
      </h6>
      <table className='table table-bordered'>
        <thead>
          <tr>
            <th scope='col'>#</th>
            <th scope='col'>SHG NAME</th>
            <th scope='col'>ADDRESS</th>
            <th scope='col'>SANGHA TYPE</th>
            <th scope='col'>NEW/RENEWAL</th>
            <th scope='col'>FORMATION DATE</th>
            <th scope='col'>LINKAGE</th>
            <th scope='col'>BANK NAME</th>
            <th scope='col'>BRANCH NAME</th>
            <th scope='col'>REP-1 NAME</th>
            <th scope='col'>REP-1 CONTACT NO</th>
            <th scope='col'>REP-2 NAME</th>
            <th scope='col'>REP-2 CONTACT NO</th>
            <th scope='col'>TOTAL NO OF MEMBERS </th>
            <th scope='col'>LOAN AMOUNT</th>
            <th scope='col'>LOAN DATE</th>
            <th scope='col'>FIELD OFFICER NAME</th>
            <th scope='col'>SECURITY DEPOSITE AMOUNT</th>
            <th scope='col'>RISK FUND AMOUNT</th>
            <th scope='col'>SERVICE CHARGE</th>
            <th scope='col'>ENROLMENT FEES</th>
            <th scope='col'>REMARKS</th>
          </tr>
        </thead>
        <tbody>
          {lending &&
            lending.map((lending, id) => {
              return (
                <tr key={lending._id}>
                  <th scope='row'>{id + 1}</th>
                  <td>{lending.name}</td>
                  <td>{lending.address}</td>
                  <td>{lending.type}</td>

                  <td>{lending.newSangha ? 'New' : 'Renew'}</td>
                  <td>{getDate(lending.formationDate)}</td>
                  <td>{lending.linkage}</td>
                  <td>{lending.BankData[0] && lending.BankData[0].name}</td>
                  <td>
                    {lending.BankData[0] && lending.BankData[0].branchName}
                  </td>
                  <td>
                    {lending.representative1[0] &&
                      lending.representative1[0].memberName}
                  </td>
                  <td>
                    {lending.representative1[0] &&
                      lending.representative1[0].contactNo}
                  </td>
                  <td>
                    {lending.representative2[0] &&
                      lending.representative2[0].memberName}
                  </td>
                  <td>
                    {lending.representative2[0] &&
                      lending.representative2[0].contactNo}
                  </td>
                  <td>{lending.memberCount}</td>
                  <td>{lending.loanAmount}</td>
                  <td>{getDate(lending.loanDate)}</td>

                  <td>
                    {lending.EmployeeData[0] && lending.EmployeeData[0].name}
                  </td>
                  <td>{lending.securityDeposit}</td>
                  <td>{lending.totalRisk}</td>
                  <td>{lending.serviceCharge}</td>
                  <td>{lending.enrollmentFee}</td>
                  <td>{lending.Remarks}</td>
                </tr>
              )
            })}
        </tbody>
      </table>
    </div>
  )
})

export default PrintIncomeReoprt
