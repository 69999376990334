import React from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { Alert, Card } from 'react-bootstrap'
import { Navigate } from 'react-router-dom'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../../features/userSlice'

import Loader from '../../components/Loader'

const LoginScreen = () => {
  const [userInfo, setUserInfo] = useState({ userName: '', password: '' })
  const dispatch = useDispatch()
  const { user, isLoading, error } = useSelector((state) => state.user)
  if (user) {
    return <Navigate to='/' />
  }

  return (
    <div
      className='d-flex mt-5 justify-content-center align-items-center'
      style={{ minHeight: '60vh' }}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <Card className='col-11 col-md-7 col-lg-5 p-5 shadow'>
          {error && <Alert variant='danger'>{error}</Alert>}
          <Form>
            <h2 className='text-center'>Login</h2>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type='email'
                placeholder='Enter email'
                required
                onChange={(e) =>
                  setUserInfo({ ...userInfo, userName: e.target.value })
                }
                value={userInfo.userName}
              />
            </Form.Group>

            <Form.Group className='mb-3' controlId='formBasicPassword'>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Password'
                required
                onChange={(e) =>
                  setUserInfo({ ...userInfo, password: e.target.value })
                }
                value={userInfo.password}
              />
            </Form.Group>
            <Button
              variant='primary'
              type='submit'
              onClick={(e) => {
                e.preventDefault()
                dispatch(login(userInfo))
              }}
            >
              Submit
            </Button>
          </Form>
        </Card>
      )}
    </div>
  )
}

export default LoginScreen
