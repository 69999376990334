import React, { useEffect } from 'react'
import { useState } from 'react'
import { Alert, Button, Card, Collapse, Form, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import { Link, useNavigate } from 'react-router-dom'
import Loader from '../components/Loader'
import Paginate from '../components/Paginate'
import { toast } from 'react-toastify'

import {
  getOneEmployee,
  resetState,
  payIncentive,
  updateEmployeePass,
} from '../features/employeeSlice'
import img from '../Logo.png'
const EmployeeProfile = () => {
  const [filter, setFilter] = useState({})
  const [showRepayment, setShowRepayment] = useState(false)
  const [showSangha, setShowSangha] = useState(false)
  const [changePassShow, setChangePassShow] = useState(false)
  const [rr, setrr] = useState(0)
  const [sc, setsc] = useState(0)

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()
  const params = searchParams.toString()

  const { isLoading, error, employee, addIncetive, updatePassSuccess } =
    useSelector((state) => state.employee)

  const onChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value })
  }

  const onSubmit = (e) => {
    e.preventDefault()

    setSearchParams({ ...filter })
  }

  const toastOptions = {
    position: 'top-right',
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: 'light',
  }

  useEffect(() => {
    if (updatePassSuccess) toast.success('Password Updated', toastOptions)
  }, [updatePassSuccess])

  const { id } = useParams()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getOneEmployee({ id, params }))
  }, [dispatch, id, params, addIncetive])

  const addIncentiveFunction = (type) => {
    var data = {}
    if (type === 'RR') {
      data = {
        type,
        sanghaIdOrReceiptIdGroup: employee.newRepaymentIds,
        numberOfSanghaOrReceiptId: employee.newRepaymentCount,
        totalLoanOrRepaymentAmount: employee.repaymentAmountTotal,
        incentiveAmount: rr,
      }
      if (rr) dispatch(payIncentive({ id, data }))
      else toast.error('enter Incentive amount', toastOptions)
    } else if (type === 'SC') {
      data = {
        type,
        sanghaIdOrReceiptIdGroup: employee.newSanghaIds,
        numberOfSanghaOrReceiptId: employee.newSanghaCreationCount,
        totalLoanOrRepaymentAmount: employee.sanghaCreationAmountTotal,
        incentiveAmount: sc,
      }
      if (sc) dispatch(payIncentive({ id, data }))
      else toast.error('enter Incentive amount', toastOptions)
    }
  }

  return (
    <div className='total-container'>
      <div className='d-flex align-items-center'>
        <Button
          variant='light'
          onClick={() => navigate(-1)}
          className='mx-3 mb-3 px-4'
        >
          {'🡐 Back'}
        </Button>
        <p>Employee Profile</p>
      </div>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Alert variant='danger'>{error}</Alert>
      ) : (
        <>
          <div className='mx-3 d-flex flex-wrap gap-3 justify-content-between'>
            <h4>Employee Details</h4>
          </div>
          <hr />
          <div className='mx-3 d-flex flex-wrap justify-content-between gap-3'>
            {employee.items && (
              <>
                <div>
                  <h5>Employee Name : {employee.items.name}</h5>
                  <p>Phone : {employee.items.phone}</p>
                  <p>email : {employee.items.email}</p>
                  <p>DOB: {employee.items.dateOfBirth.split('T')[0]}</p>
                  <p>Blood Group : {employee.items.bloodGroup}</p>
                  <p>Aadhar: {employee.items.aadhar}</p>

                  <div className='d-flex flex-wrap gap-3'>
                    <p>Address : </p>
                    <p style={{ whiteSpace: 'pre-line' }}>
                      {employee.items.address}
                    </p>
                  </div>
                  <h5>
                    Bank Name : {employee.items.bankName} ,{' '}
                    {employee.items.branchName}
                  </h5>
                  <p>ISFC : {employee.items.ifscCode}</p>

                  <p> Account : {employee.items.bankAccNumber}</p>
                </div>
                <div>
                  <img
                    src={employee.items.photo || img}
                    alt={'Employee Img'}
                    style={{
                      height: '250px',
                      maxWidth: '100%',
                      objectFit: 'contain',
                    }}
                    className='mb-5'
                  />
                </div>
              </>
            )}
          </div>
          <div className='mb-3'>
            <Button onClick={() => setChangePassShow(true)}>
              Update Password
            </Button>
            <UpdatePassword
              show={changePassShow}
              onHide={() => setChangePassShow(false)}
            />
          </div>
          <div>
            <Link to='edit'>
              <Button onClick={() => dispatch(resetState())}>
                Edit Employee Details
              </Button>
            </Link>
          </div>
          <hr />
          <div className='d-flex flex-column flex-md-row gap-5  flex-grow-1 align-items-md-end mb-4'>
            <Form.Group>
              <Form.Label>From</Form.Label>
              <Form.Control
                name='fromDateForIncentive'
                type='date'
                onChange={onChange}
                value={filter.fromDateForIncentive}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>To</Form.Label>
              <Form.Control
                name='toDateForIncentive'
                type='date'
                onChange={onChange}
                value={filter.toDateForIncentive}
              />
            </Form.Group>
            <Button onClick={onSubmit}>Submit</Button>
          </div>
          <div className='d-flex flex-wrap gap-5'>
            <div>
              <h5>New Sanghas Created : {employee.newSanghaCreationCount}</h5>
              <h5>New Loan Amount : {employee.sanghaCreationAmountTotal}</h5>
              <Form.Group className='mb-3 w-100'>
                <Form.Label>Enter Incentive Amount</Form.Label>
                <Form.Control
                  type='number'
                  placeholder='Enter Incentive Amount'
                  min={0}
                  style={{ maxWidth: '15rem' }}
                  onChange={(e) => setsc(e.target.value)}
                />
              </Form.Group>
              <Button onClick={() => addIncentiveFunction('SC')}>pay</Button>
            </div>
            <div>
              <h5>
                New Repayment Receipts Created : {employee.newRepaymentCount}
              </h5>
              <h5>New Repayment Amount : {employee.repaymentAmountTotal}</h5>
              <Form.Group className='mb-3 w-100'>
                <Form.Label>Enter Incentive Amount</Form.Label>
                <Form.Control
                  type='number'
                  placeholder='Enter Incentive Amount'
                  min={0}
                  style={{ maxWidth: '15rem' }}
                  onChange={(e) => setrr(e.target.value)}
                />
              </Form.Group>
              <Button onClick={() => addIncentiveFunction('RR')}>pay</Button>
            </div>
          </div>

          <hr />
          <div
            className='d-flex flex-wrap gap-3 justify-content-between align-items-end cursor-pointer mx-3'
            onClick={() => setShowRepayment(!showRepayment)}
          >
            <div className='d-flex gap-4 flex-wrap'>
              <h5 className='m-0'> Incentive Details</h5>
            </div>
            <p
              className='m-0 cursor-pointer small'
              onClick={() => setShowRepayment(!showRepayment)}
            >
              {showRepayment ? 'Show Less' : 'Show more'}
            </p>
          </div>
          <Collapse in={showRepayment}>
            <div className='total-container'>
              <div className='d-flex justify-content-center flex-wrap'>
                {employee.allIncentivesOfEmployee &&
                  employee.allIncentivesOfEmployee.map((ele) => {
                    return (
                      <div
                        className='col-12 col-md-4 col-lg-3 card-container'
                        key={ele._id}
                      >
                        <Card className='p-3 h-100'>
                          {(ele.SanghaData && (
                            <Link to={`/sangha-list/${ele.SanghaData._id}`}>
                              <h5 className='text-center'>
                                {ele.SanghaData.name}
                              </h5>
                            </Link>
                          )) ||
                            (ele.ReceiptData && (
                              <Link to={`/sangha-list/${ele.ReceiptData._id}`}>
                                <h5 className='text-center'>
                                  {ele.ReceiptData.sanghaId &&
                                    ele.ReceiptData.sanghaId.name}
                                </h5>
                              </Link>
                            ))}

                          <hr />
                          <h6 className='text-center'>
                            Incentive Type :{' '}
                            {ele.incentiveType === 'RR'
                              ? 'Repayment'
                              : 'Sangha'}{' '}
                          </h6>
                          <h6 className='text-center'>
                            {ele.incentiveType === 'RR' ? 'Repayment' : 'Loan'}{' '}
                            amount : {ele.loanOrRepaymentAmount}
                          </h6>
                          {ele.ReceiptData && (
                            <p className='text-center'>
                              Receipt Number : {ele.ReceiptData.receiptId}
                            </p>
                          )}

                          {(ele.SanghaData && (
                            <p className='text-center'>
                              Bank :{' '}
                              {ele.SanghaData.bankName &&
                                ele.SanghaData.bankName.name +
                                  ' , ' +
                                  ele.SanghaData.bankName.branchName}
                            </p>
                          )) ||
                            (ele.ReceiptData && (
                              <p className='text-center'>
                                Bank :{' '}
                                {ele.ReceiptData.bankId &&
                                  ele.ReceiptData.bankId.name +
                                    ' , ' +
                                    ele.ReceiptData.bankId.branchName}
                              </p>
                            ))}

                          <p className='text-center'>
                            Incentive Date :{' '}
                            {ele.incentiveGeneratedDate.split('T')[0]}
                          </p>
                          <p className='text-center'>
                            Paid Date :{' '}
                            {ele.incentiveGroupId
                              ? ele.incentiveGroupId.incentivePaidDate.split(
                                  'T'
                                )[0]
                              : 'NA'}
                          </p>
                        </Card>
                      </div>
                    )
                  })}
              </div>
              <div className='d-flex justify-content-end me-3'>
                <Paginate
                  type={'incentive'}
                  page={employee.empincentiveCurrentPage}
                  pages={employee.empincentiveTotalPages}
                />
              </div>
            </div>
          </Collapse>
          <hr />
          <div
            className='d-flex flex-wrap gap-3 justify-content-between align-items-end cursor-pointer mx-3'
            onClick={() => setShowSangha(!showSangha)}
          >
            <div className='d-flex gap-4 flex-wrap'>
              <h5 className='m-0'>Paid Incentive Detail</h5>
            </div>
            <p
              className='m-0 cursor-pointer small'
              onClick={() => setShowSangha(!showSangha)}
            >
              {showSangha ? 'Show Less' : 'Show more'}
            </p>
          </div>
          <Collapse in={showSangha}>
            <div className='total-container'>
              <div className='d-flex justify-content-center flex-wrap'>
                {employee.items &&
                  employee.items.incentiveData &&
                  employee.items.incentiveData.map((ele) => {
                    return (
                      <div className='col-12 col-md-4 col-lg-3 card-container'>
                        <Card className='p-3 h-100'>
                          <h6 className='text-center'>
                            Total{' '}
                            {ele.incentiveType === 'SC' ? 'Loan' : 'Repayment'}{' '}
                            amount : {ele.totalLoanOrRepaymentAmount}
                          </h6>
                          <h6 className='text-center'>
                            Incentive amount : {ele.incentiveAmount}
                          </h6>
                          <p className='text-center'>
                            Incentive Type :{' '}
                            {ele.incentiveType === 'SC'
                              ? 'Sangha Creation'
                              : 'Repayment Receipt'}
                          </p>
                          <p className='text-center'>
                            {ele.incentiveType === 'SC'
                              ? 'Sanghas'
                              : 'Receipts'}{' '}
                            Created : {ele.totalSanghaIdOrReceiptId}
                          </p>

                          <p className='text-center'>
                            Payment Date Date :{' '}
                            {ele.incentivePaidDate.split('T')[0]}
                          </p>
                        </Card>
                      </div>
                    )
                  })}
              </div>
              <div className='d-flex justify-content-end me-3'>
                <Paginate
                  type='paidIncentive'
                  page={employee.incentiveCurrentPage}
                  pages={employee.incentiveTotalPages}
                />
              </div>
            </div>
          </Collapse>
          <hr />
        </>
      )}
    </div>
  )
}

function UpdatePassword(props) {
  const [data, setData] = useState({ newPassword: '', reEnteredPassword: '' })
  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }
  const [error, setError] = useState(false)
  const dispatch = useDispatch()
  const { id } = useParams()
  const onSubmit = (e) => {
    e.preventDefault()
    var temp = false
    for (const key in data) {
      if (!data[key]) {
        temp = true
        break
      }
    }
    if (data.newPassword !== data.reEnteredPassword) temp = true

    if (!temp) {
      dispatch(updateEmployeePass({ id, data }))
      props.onHide()

      setData({
        password: '',
        repassword: '',
      })
    }
    setError(temp)
  }
  return (
    <Modal
      {...props}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          Update Password
        </Modal.Title>
      </Modal.Header>
      <Form>
        <Modal.Body>
          <Form.Group className='mb-3 w-100'>
            <Form.Label>Enter New Password</Form.Label>
            <Form.Control
              className={`w-100 ${error && !data.newPassword && 'is-invalid'}`}
              type='password'
              placeholder='Enter Password'
              required
              name='newPassword'
              value={data.newPassword}
              onChange={onChange}
            />
            <div className='invalid-feedback'>This Field is required</div>
          </Form.Group>
          <Form.Group className='mb-3 w-100'>
            <Form.Label>Re-enter New Password</Form.Label>
            <Form.Control
              className={`w-100 ${
                error &&
                (!data.reEnteredPassword ||
                  data.reEnteredPassword !== data.password) &&
                'is-invalid'
              }`}
              type='password'
              placeholder='Re-Enter Password'
              required
              name='reEnteredPassword'
              value={data.reEnteredPassword}
              onChange={onChange}
            />
            <div className='invalid-feedback'>{`${
              !data.reEnteredPassword
                ? 'This Field is required'
                : data.reEnteredPassword !== data.password &&
                  'Password doesnot match'
            }`}</div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onSubmit} type='submit'>
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default EmployeeProfile
