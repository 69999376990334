import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from './axios/axios'
import { logout } from './userSlice'

const initialState = {
  bankList: [],
  bank: {},
  error: null,
  isLoading: false,
  currentPage: 0,
  totalPages: 0,
  addSuccess: false,
  updateSuccess: false,
  bankData: [],
}

export const getBankList = createAsyncThunk(
  'bank/getBankList',
  async (obj, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    try {
      const { user } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.user.token}`,
        },
      }
      const res = await axios.get(`/api/bank?${obj}`, config)

      return fulfillWithValue(res.data)
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        dispatch(logout(error.response.data))
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const getOneBank = createAsyncThunk(
  'bank/getOneBank',
  async (
    { id, params },
    { rejectWithValue, fulfillWithValue, getState, dispatch }
  ) => {
    try {
      const { user } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.user.token}`,
        },
      }
      const res = await axios.get(`/api/bank/${id}?${params}`, config)

      const items = {
        ...res.data.items,
        currentLendingPage: res.data.currentLendingPage,
        currentReceiptPage: res.data.currentReceiptPage,
        totalLendPage: res.data.totalLendPage,
        totalReceiptPage: res.data.totalReceiptPage,
      }

      return fulfillWithValue(items)
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        dispatch(logout(error.response.data))
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const addBank = createAsyncThunk(
  'bank/addBank',
  async (obj, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    try {
      const { user } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.user.token}`,
        },
      }
      const res = await axios.post(`/api/bank`, { ...obj }, config)

      return fulfillWithValue(res.data)
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        dispatch(logout(error.response.data))
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateBank = createAsyncThunk(
  'bank/updateBank',
  async (
    { id, data },
    { rejectWithValue, fulfillWithValue, getState, dispatch }
  ) => {
    try {
      const { user } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.user.token}`,
        },
      }
      const res = await axios.put(`/api/bank/${id}`, { ...data }, config)

      return fulfillWithValue(res.data)
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        dispatch(logout(error.response.data))
      }
      return rejectWithValue(error.response.data)
    }
  }
)

const bankSlice = createSlice({
  name: 'bank',
  initialState,

  reducers: {
    resetState: (state) => {
      state.bank = {}
      state.error = ''
      state.addSuccess = false
      state.updateSuccess = false
    },
  },
  extraReducers: {
    [getBankList.pending]: (state) => {
      state.isLoading = true
      state.error = ''
    },
    [getBankList.fulfilled]: (state, action) => {
      state.isLoading = false
      state.bankList = action.payload.items
      state.currentPage = action.payload.currentPage
      state.totalPages = action.payload.totalPages
      state.bankData = action.payload.BankData
    },
    [getBankList.rejected]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    [addBank.pending]: (state) => {
      state.isLoading = true
      state.error = ''
    },
    [addBank.fulfilled]: (state, action) => {
      state.isLoading = false
      state.addSuccess = true
    },
    [addBank.rejected]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    [getOneBank.pending]: (state) => {
      state.isLoading = true
      state.error = ''
    },
    [getOneBank.fulfilled]: (state, action) => {
      state.isLoading = false
      state.bank = action.payload
    },
    [getOneBank.rejected]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    [updateBank.pending]: (state) => {
      state.isLoading = true
      state.error = ''
    },
    [updateBank.fulfilled]: (state, action) => {
      state.isLoading = false
      state.updateSuccess = true
    },
    [updateBank.rejected]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})
export const { resetState } = bankSlice.actions

export default bankSlice.reducer
