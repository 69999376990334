import React from 'react'

const PrintRiskFund = React.forwardRef(({ demand, filter }, ref) => {
  const getDate = (date) => {
    const DueDate = new Date(date)

    var dd = String(DueDate.getDate()).padStart(2, '0')
    var mm = String(DueDate.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = DueDate.getFullYear()
    return dd + '/' + mm + '/' + yyyy
  }

  return (
    <div ref={ref} className='m-5'>
      <h5 className='m-3 text-center'>Risk Fund report</h5>
      <h6 className='m-3 text-center'>filters : {filter}</h6>
      <table className='table table-bordered'>
        <thead>
          <tr>
            <th scope='col'>#</th>
            <th scope='col'>SHG NAME</th>
            <th scope='col'>ADDRESS</th>
            <th scope='col'>SANGHA TYPE</th>
            <th scope='col'>NEW/RENEWAL</th>

            <th scope='col'>BANK NAME</th>
            <th scope='col'>BRANCH NAME</th>

            <th scope='col'>TOTAL NO OF MEMBERS </th>
            <th scope='col'>LOAN AMOUNT</th>
            <th scope='col'>LOAN DATE</th>

            <th scope='col'>FIELD OFFICER NAME</th>
            <th scope='col'>NUMBER OF YEARS</th>
            <th scope='col'>TOTAL RISK FUND AMOUNT</th>
            <th scope='col'>NUMBER OF RISK FUNDS</th>
            <th scope='col'>RENEWAL DATE</th>
            <th scope='col'>NUMBER DEATH CASE</th>
            <th scope='col'>TOTAL DEATH CLAIM AMOUNT</th>
          </tr>
        </thead>
        <tbody>
          {demand.map((demand, id) => {
            return (
              <tr key={demand._id}>
                <th scope='row'>{id + 1}</th>
                <td>{demand.name}</td>
                <td>{demand.address}</td>
                <td>{demand.type}</td>

                <td>{demand.newSangha ? 'New' : 'Renew'}</td>

                <td>{demand.BankData[0] && demand.BankData[0].name}</td>
                <td>{demand.BankData[0] && demand.BankData[0].branchName}</td>

                <td>{demand.memberCount}</td>
                <td>{demand.loanAmount}</td>
                <td>{getDate(demand.loanDate)}</td>
                <td>{demand.EmployeeData[0] && demand.EmployeeData[0].name}</td>

                <td>
                  {demand.TotalRiskAmount && demand.TotalRiskAmount.noOfyears}
                </td>
                <td>
                  {demand.TotalRiskAmount &&
                    demand.TotalRiskAmount.riskFundAmount}
                </td>
                <td>
                  {demand.TotalRiskAmount &&
                    demand.TotalRiskAmount.noOfMembersInRiskFund}
                </td>
                <td>
                  {demand.TotalRiskAmount &&
                    getDate(demand.TotalRiskAmount.latestRenewalDate)}
                </td>
                <td>
                  {demand.TotalRiskAmount &&
                    demand.TotalRiskAmount.noOfDeathCase}
                </td>
                <td>
                  {demand.TotalRiskAmount &&
                    demand.TotalRiskAmount.totalDeathClaimAmount}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
})

export default PrintRiskFund
