import React from 'react'
import img from '../../Logo.png'

import QRCode from 'react-qr-code'
import Barcode from 'react-barcode'
import { Table } from 'react-bootstrap'

const PrintReceipt = React.forwardRef(({ receipt }, ref) => {
  return (
    <div ref={ref}>
      <div className='border p-1 m-2'>
        <div style={{ borderBottom: '3px dashed' }} className='pb-2'>
          <Table borderless size='sm'>
            <thead>
              <tr>
                <th scope='col' colSpan='2'>
                  <div className='d-flex align-items-center justify-content-center'>
                    <img
                      src={img}
                      alt='snehajeevana'
                      style={{ height: '50px' }}
                    />
                    <h1 className='text-center ' style={{ fontSize: '24px' }}>
                      SJF
                    </h1>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className='w-100'>
              <tr>
                <th colSpan='2'>
                  <h2
                    style={{ borderBottom: '3px dashed', fontSize: '18px' }}
                    className='text-center'
                  >
                    1st Cross, Hospet ext, Near Aralimara Hotel, Gubbi-572216,
                    Tumkur
                  </h2>
                </th>
              </tr>
              <tr>
                <th style={{ fontSize: '18px' }}>Sangha Name:</th>
                <td
                  style={{ fontSize: '16px' }}
                  className='ps-2 text-end fw-bold'
                >
                  {receipt.name}
                </td>
              </tr>
              <tr>
                <th style={{ fontSize: '18px' }}>Receipt No:</th>
                <td
                  style={{ fontSize: '16px' }}
                  className='ps-2 text-end fw-bold'
                >
                  {receipt.receiptId}
                </td>
              </tr>
              <tr>
                <th style={{ fontSize: '18px' }}> Date:</th>
                <td
                  style={{ fontSize: '16px' }}
                  className='ps-2 text-end fw-bold'
                >
                  {receipt.receiptGenerationDate &&
                    receipt.receiptGenerationDate.split('T')[0]}
                </td>
              </tr>

              <tr>
                <th style={{ fontSize: '18px' }}>Bank:</th>
                <td
                  style={{ fontSize: '16px' }}
                  className='ps-2 text-end fw-bold'
                >
                  {receipt.bankData &&
                    receipt.bankData[0] &&
                    receipt.bankData[0].name}
                </td>
              </tr>
              <tr>
                <th style={{ fontSize: '18px' }}>Branch:</th>
                <td
                  style={{ fontSize: '16px' }}
                  className='ps-2 text-end fw-bold'
                >
                  {receipt.bankData &&
                    receipt.bankData[0] &&
                    receipt.bankData[0].branchName}
                </td>
              </tr>
              <tr>
                <th style={{ fontSize: '18px' }}>
                  {receipt.type === 'Loan' ? 'Loan account' : 'Savings account'}{' '}
                  number:
                </th>
                <td
                  style={{ fontSize: '16px' }}
                  className='ps-2 text-end fw-bold'
                >
                  {' '}
                  {receipt.type === 'Loan'
                    ? receipt.loanAccountNumber
                    : receipt.savingsAccountNumber}
                </td>
              </tr>
              <tr>
                <th style={{ fontSize: '18px' }}>Loan amount:</th>
                <td
                  style={{ fontSize: '16px' }}
                  className='ps-2 text-end fw-bold'
                >
                  {' '}
                  {receipt.loanAmount}
                </td>
              </tr>
              <tr>
                <th style={{ fontSize: '18px' }}>
                  {' '}
                  {receipt.type === 'Loan' ? 'Repayment' : 'Savings'} amount:
                </th>
                <td
                  style={{ fontSize: '16px' }}
                  className='ps-2 text-end fw-bold'
                >
                  {' '}
                  {receipt.amount}
                </td>
              </tr>
              <tr>
                <th style={{ fontSize: '18px' }}>Field officer Name:</th>
                <td
                  style={{ fontSize: '16px' }}
                  className='ps-2 text-end fw-bold'
                >
                  {receipt.employeeData &&
                    receipt.employeeData[0] &&
                    receipt.employeeData[0].name}
                </td>
              </tr>
            </tbody>
          </Table>
          <div className='mt-3 d-flex justify-content-between'>
            <div>
              <QRCode
                value={JSON.stringify({
                  repaymentAmount: receipt.repaymentAmount,
                  receiptId: receipt.receiptId,
                  accountNumber:
                    receipt.type === 'Loan'
                      ? receipt.loanAccountNumber
                      : receipt.savingsAccountNumber,
                })}
                size={128}
                style={{ height: 'auto', maxWidth: '50px', width: '50px' }}
                viewBox={`0 0 128 128`}
              />
            </div>
            <div>
              <Barcode
                value={receipt.receiptId || 'na'}
                height={40}
                margin={0}
                width={1}
                marginLeft={0}
                fontSize={10}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default PrintReceipt
