import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { Alert, Card } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  addEmployee,
  getOneEmployee,
  updateEmployee,
} from '../features/employeeSlice'
import Loader from '../components/Loader'

const CreateEmployee = () => {
  const navigate = useNavigate()

  const [data, setData] = useState({
    name: '',
    phone: '',
    photo: '',
    address: '',
    dateOfBirth: '',
    bloodGroup: '',
    aadhar: '',
    email: '',
    bankName: '',
    branchName: '',
    bankAccNumber: '',
    ifscCode: '',
    type: '',
  })
  const [validateError, setError] = useState(false)

  const { id } = useParams()

  const dispatch = useDispatch()
  const { isLoading, employee, addSuccess, updateSuccess, error } = useSelector(
    (state) => state.employee
  )

  useEffect(() => {
    if (id) {
      dispatch(getOneEmployee({ id }))
    }
  }, [id, dispatch])
  useEffect(() => {
    const toastOptions = {
      position: 'top-right',
      autoClose: 8000,
      pauseOnHover: true,
      draggable: true,
      theme: 'light',
    }
    if (addSuccess) {
      toast.success('Employee Created', toastOptions)
      setData({
        name: '',
        phone: '',
        photo: '',
        address: '',
        dateOfBirth: '',
        bloodGroup: '',
        aadhar: '',
        email: '',
        bankName: '',
        branchName: '',
        bankAccNumber: '',
        ifscCode: '',
      })
    }
    if (id && updateSuccess) {
      toast.success('Employee Updated', toastOptions)
      setData({
        name: '',
        phone: '',
        photo: '',
        address: '',
        dateOfBirth: '',
        bloodGroup: '',
        aadhar: '',
        email: '',
        bankName: '',
        branchName: '',
        bankAccNumber: '',
        ifscCode: '',
      })
    }
  }, [id, dispatch, addSuccess, updateSuccess])

  useEffect(() => {
    if (id && employee.items) {
      setData({
        name: employee.items.name,
        phone: employee.items.phone,
        photo: employee.items.photo,
        address: employee.items.address,
        dateOfBirth: employee.items.dateOfBirth.split('T')[0],
        bloodGroup: employee.items.bloodGroup,
        aadhar: employee.items.aadhar,
        email: employee.items.email,
        bankName: employee.items.bankName,
        branchName: employee.items.branchName,
        bankAccNumber: employee.items.bankAccNumber,
        ifscCode: employee.items.ifscCode,
        type: employee.items.type,
      })
    }
  }, [id, employee])

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }
  const onSubmit = (e) => {
    e.preventDefault()
    if (id) {
      dispatch(updateEmployee({ id, data }))
    } else dispatch(addEmployee(data))
  }
  return (
    <div className='total-container'>
      <Button
        variant='light'
        onClick={() => navigate(-1)}
        className='mx-3 mb-3 px-4'
      >
        {'🡐 Back'}
      </Button>
      {isLoading ? (
        <Loader />
      ) : (
        <div className='d-flex my-5 justify-content-center align-items-center'>
          <Card className='col-12 col-md-7 col-lg-5 p-4 shadow'>
            {error && <Alert variant='danger'>{error}</Alert>}
            <Form onSubmit={onSubmit}>
              <h2 className='text-center'>
                {id ? 'Update' : 'Create'} Employee
              </h2>
              <hr />

              <Form.Group className='mb-3'>
                <Form.Label>Employee Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Employee Name'
                  className={`w-100 ${
                    validateError && !data.name && 'is-invalid'
                  }`}
                  name='name'
                  value={data.name}
                  onChange={onChange}
                />
                <div className='invalid-feedback'>This Field is required</div>
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Address'
                  as='textarea'
                  rows={3}
                  className={`w-100 ${
                    validateError && !data.address && 'is-invalid'
                  }`}
                  name='address'
                  value={data.address}
                  onChange={onChange}
                />
                <div className='invalid-feedback'>This Field is required</div>
              </Form.Group>

              <div className=''>
                <Form.Group className='mb-3'>
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type='tel'
                    placeholder='Enter employee Phone'
                    className={`w-100 ${
                      validateError && !data.phone && 'is-invalid'
                    }`}
                    name='phone'
                    value={data.phone}
                    onChange={onChange}
                  />
                  <div className='invalid-feedback'>This Field is required</div>
                </Form.Group>
                <Form.Group className='mb-3'>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Employee Email'
                    className={`w-100 ${
                      validateError && !data.email && 'is-invalid'
                    }`}
                    name='email'
                    value={data.email}
                    onChange={onChange}
                  />
                  <div className='invalid-feedback'>This Field is required</div>
                </Form.Group>
              </div>

              <Form.Group className='mb-3'>
                <Form.Label>Employee Photo</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Employee Photo'
                  className={`w-100 ${
                    validateError && !data.photo && 'is-invalid'
                  }`}
                  name='photo'
                  value={data.photo}
                  onChange={onChange}
                />
                <div className='invalid-feedback'>This Field is required</div>
              </Form.Group>
              <div className=''>
                <Form.Group className='mb-3'>
                  <Form.Label>date of Birth</Form.Label>
                  <Form.Control
                    type='date'
                    className={`w-100 ${
                      validateError && !data.dateOfBirth && 'is-invalid'
                    }`}
                    name='dateOfBirth'
                    value={data.dateOfBirth}
                    onChange={onChange}
                  />
                  <div className='invalid-feedback'>This Field is required</div>
                </Form.Group>
                <Form.Group className='mb-3 w-100' controlId='formBasicEmail'>
                  <Form.Label>Blood Group</Form.Label>
                  <Form.Select
                    onChange={onChange}
                    name='bloodGroup'
                    value={data.bloodGroup}
                    className={`w-100 ${
                      error && !data.bloodGroup && 'is-invalid'
                    }`}
                  >
                    <option value=''>Select Blood Group</option>
                    <option value='A+'>A+</option>
                    <option value='A-'>A-</option>
                    <option value='AB+'>AB+</option>
                    <option value='AB-'>AB-</option>
                    <option value='B+'>B+</option>
                    <option value='B-'>B-</option>
                    <option value='O+'>O+</option>
                    <option value='O-'>O-</option>
                  </Form.Select>
                  <div className='invalid-feedback'>This Field is required</div>
                </Form.Group>
              </div>

              <Form.Group className='mb-3'>
                <Form.Label>Aadhar</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Employee Aadhar'
                  className={`w-100 ${
                    validateError && !data.aadhar && 'is-invalid'
                  }`}
                  name='aadhar'
                  value={data.aadhar}
                  onChange={onChange}
                />
                <div className='invalid-feedback'>This Field is required</div>
              </Form.Group>
              <Form.Group className='mb-3 w-100' controlId='formBasicEmail'>
                <Form.Label>Employee Type</Form.Label>
                <Form.Select
                  onChange={onChange}
                  name='type'
                  value={data.type}
                  className={`w-100 ${
                    validateError && !data.bank && 'is-invalid'
                  }`}
                >
                  <option value=''>Select Type</option>
                  <option value='Admin'>Admin</option>
                  <option value='Manager'>Manager</option>
                  <option value='Field Officer'>Field Officer</option>
                </Form.Select>
                <div className='invalid-feedback'>This Field is required</div>
              </Form.Group>
              <hr />
              <h5 className='text-center'>Employee Bank Details</h5>
              <hr />
              <div className=''>
                <Form.Group className='mb-3'>
                  <Form.Label>Bank Name</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Bank Name'
                    className={`w-100 ${
                      validateError && !data.bankName && 'is-invalid'
                    }`}
                    name='bankName'
                    value={data.bankName}
                    onChange={onChange}
                  />
                  <div className='invalid-feedback'>This Field is required</div>
                </Form.Group>
                <Form.Group className='mb-3'>
                  <Form.Label>Branch Name</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Branch Name'
                    className={`w-100 ${
                      validateError && !data.branchName && 'is-invalid'
                    }`}
                    name='branchName'
                    value={data.branchName}
                    onChange={onChange}
                  />
                  <div className='invalid-feedback'>This Field is required</div>
                </Form.Group>
              </div>
              <Form.Group className='mb-3'>
                <Form.Label>Account Number</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Account Number'
                  className={`w-100 ${
                    validateError && !data.bankAccNumber && 'is-invalid'
                  }`}
                  name='bankAccNumber'
                  value={data.bankAccNumber}
                  onChange={onChange}
                />
                <div className='invalid-feedback'>This Field is required</div>
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>IFSC Code</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter IFSC Code'
                  className={`w-100 ${
                    validateError && !data.ifscCode && 'is-invalid'
                  }`}
                  name='ifscCode'
                  value={data.ifscCode}
                  onChange={onChange}
                />
                <div className='invalid-feedback'>This Field is required</div>
              </Form.Group>
              <Button variant='primary' type='submit'>
                Submit
              </Button>
            </Form>
          </Card>
        </div>
      )}
    </div>
  )
}

export default CreateEmployee
