import React from 'react'

import { Link } from 'react-router-dom'

const PageNotFound = () => {
  return (
    <div className='d-flex justify-content-center align-items-center vh-100'>
      <div>
        <h1 className='display-1 fw-bold '>404</h1>
        <Link to='/'>{`<- Back to Home`}</Link>
      </div>
    </div>
  )
}

export default PageNotFound
