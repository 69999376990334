import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from './axios/axios'
import { logout } from './userSlice'

const initialState = {
  duepaymentList: [],
  duepaymentPrintList: [],
  bankData: [],
  employeeData: [],
  error: null,
  isLoading: false,
  currentPage: 0,
  totalPages: 0,
  filter: '',
}

export const getDuepaymentList = createAsyncThunk(
  'duepayment/getDuepaymentList',
  async (obj, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    try {
      const { user } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.user.token}`,
        },
      }
      const res = await axios.get(`/api/home/duepayment?${obj}`, config)

      return fulfillWithValue(res.data)
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        dispatch(logout(error.response.data))
      }
      return rejectWithValue(error.response.data)
    }
  }
)

export const getDuepaymentPrintList = createAsyncThunk(
  'duepayment/getDuepaymentPrintList',
  async (obj, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    try {
      const { user } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.user.token}`,
        },
      }
      const res = await axios.get(`/api/print/printduedata?${obj}`, config)

      return fulfillWithValue(res.data)
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        dispatch(logout(error.response.data))
      }
      return rejectWithValue(error.response.data)
    }
  }
)

const duepaymentSlice = createSlice({
  name: 'duepayment',
  initialState,
  reducers: {
    resetState: (state) => {
      state.error = ''
      state.addSuccess = false
      state.updateSuccess = false
    },
  },
  extraReducers: {
    [getDuepaymentList.pending]: (state) => {
      state.isLoading = true
      state.error = ''
    },
    [getDuepaymentList.fulfilled]: (state, action) => {
      state.isLoading = false
      state.duepaymentList = action.payload.items
      state.employeeData = action.payload.EmployeeData
      state.bankData = action.payload.BankData
      state.currentPage = action.payload.currentPage
      state.totalPages = action.payload.totalPages
    },
    [getDuepaymentList.rejected]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },

    [getDuepaymentPrintList.fulfilled]: (state, action) => {
      state.isLoading = false
      state.duepaymentPrintList = action.payload.items
      state.filter = action.payload.Filter
      state.error = ''
    },
    [getDuepaymentPrintList.rejected]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export default duepaymentSlice.reducer
