import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer style={{ backgroundColor: 'black', color: 'white' }}>
      <Container>
        <Row>
          <Col className='text-center py-3' sm='12' md='6'>
            {' '}
            © H-Bros |{' '}
            <Link
              to='software'
              className='text-white'
              style={{ textDecoration: 'none' }}
            >
              Software
            </Link>
          </Col>
          <Col className='text-center py-3'> © SnehaJeevana 2022</Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
