import { useState } from 'react'
import { Alert, Button, Card, Form, FormCheck, Table } from 'react-bootstrap'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import Paginate from '../components/Paginate'

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getEmployeeList, resetState } from '../features/employeeSlice'
import Loader from '../components/Loader'

const EmployeeList = () => {
  const [filter, setFilter] = useState({})
  const [viewTable, setViewTable] = useState(false)

  const navigate = useNavigate()

  const { employeeList, currentPage, totalPages, isLoading, error } =
    useSelector((state) => state.employee)

  const [searchParams, setSearchParams] = useSearchParams()
  const onChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value })
  }
  const params = searchParams.toString()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getEmployeeList(params))
  }, [dispatch, params])

  const onSubmit = (e) => {
    e.preventDefault()
    setSearchParams(filter)
  }
  return (
    <div className='total-container'>
      <div className='d-flex align-items-center'>
        <Button
          variant='light'
          onClick={() => navigate(-1)}
          className='mx-3 mb-3 px-4'
        >
          {'🡐 Back'}
        </Button>
        <p>Employee list</p>
      </div>

      {isLoading ? (
        <Loader />
      ) : error ? (
        <Alert variant='danger'>{error}</Alert>
      ) : (
        <>
          {' '}
          <div>
            <Form>
              <div className='d-flex justify-content-center align-items-end gap-2 flex-wrap'>
                <Form.Group>
                  <Form.Control
                    type='text'
                    placeholder='Search Here'
                    style={{ minWidth: '4rem' }}
                    name='name'
                    onChange={onChange}
                  />
                </Form.Group>
                <Button
                  variant='primary'
                  className='mt-3 px-5'
                  onClick={onSubmit}
                >
                  Search
                </Button>
              </div>
            </Form>
          </div>
          <hr />
          <div className='d-flex gap-2 justify-content-between px-3 flex-wrap'>
            <Link to='/create-employee'>
              <Button variant='success' onClick={() => dispatch(resetState())}>
                Create Employee
              </Button>
            </Link>

            <FormCheck
              onChange={(e) => setViewTable(e.target.checked)}
              checked={viewTable}
              type='switch'
              id='custom-switch'
              label='Switch to Table'
            />
          </div>
          {employeeList && employeeList.length ? (
            <>
              {viewTable ? (
                <>
                  <Table responsive striped bordered hover className='mt-4'>
                    <thead>
                      <tr>
                        <th>Employee Name</th>
                        <th>Phone</th>
                        <th>email</th>
                        <th>DOB</th>
                        <th>Aadhar</th>
                        <th>Bank Name</th>
                        <th>Account Number</th>
                        <th>IFSC code </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {employeeList &&
                        employeeList.map((employee) => {
                          const dob = new Date(employee.dateOfBirth)

                          var dd = String(dob.getDate()).padStart(2, '0')
                          var mm = String(dob.getMonth() + 1).padStart(2, '0') //January is 0!
                          var yyyy = dob.getFullYear()
                          const date = mm + '/' + dd + '/' + yyyy

                          return (
                            <tr key={employee._id}>
                              <td className='text-center'>{employee.name}</td>

                              <td className='text-center'>{employee.phone}</td>
                              <td className='text-center'>{employee.email}</td>
                              <td className='text-center'>{`${date}`}</td>
                              <td className='text-center'>{employee.aadhar}</td>
                              <td className='text-center'>
                                {employee.bankName}
                              </td>
                              <td className='text-center'>
                                {employee.bankAccNumber}
                              </td>
                              <td className='text-center'>
                                {employee.ifscCode}
                              </td>
                              <td>
                                <Link
                                  to={employee._id}
                                  className='w-100 mt-auto'
                                >
                                  <Button
                                    className='w-100 '
                                    onClick={() => dispatch(resetState())}
                                  >
                                    View More
                                  </Button>
                                </Link>
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </Table>
                </>
              ) : (
                <div className='d-flex justify-content-center flex-wrap'>
                  {employeeList &&
                    employeeList.map((employee) => {
                      const dob = new Date(employee.dateOfBirth)

                      var dd = String(dob.getDate()).padStart(2, '0')
                      var mm = String(dob.getMonth() + 1).padStart(2, '0') //January is 0!
                      var yyyy = dob.getFullYear()
                      const date = mm + '/' + dd + '/' + yyyy
                      return (
                        <div
                          className='col-12 col-md-4 col-lg-3 card-container'
                          key={employee._id}
                        >
                          <Card className='p-3 h-100'>
                            <h5 className='text-center'>{employee.name}</h5>
                            <hr />
                            <h6 className='text-center'>
                              Phone : {employee.phone}
                            </h6>
                            <p className='text-center'>
                              email : {employee.email}
                            </p>
                            <p className='text-center'>{`DOB : ${date}`}</p>
                            <p className='text-center'>
                              Aadhar: {employee.aadhar}
                            </p>
                            <p className='text-center'>
                              Bank Name: {employee.bankName}
                            </p>
                            <p className='text-center'>
                              Account Number : {employee.bankAccNumber}
                            </p>
                            <p className='text-center'>
                              IFSC code : {employee.ifscCode}
                            </p>
                            <Link to={employee._id} className='w-100 mt-auto'>
                              <Button
                                className='w-100 '
                                onClick={() => dispatch(resetState())}
                              >
                                View More
                              </Button>
                            </Link>
                          </Card>
                        </div>
                      )
                    })}
                </div>
              )}

              <div className='d-flex justify-content-end me-3'>
                <Paginate page={currentPage} pages={totalPages} />
              </div>
            </>
          ) : (
            <>
              <h4 className='text-center'>No Data Found</h4>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default EmployeeList
