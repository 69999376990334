import React, { createRef, useEffect } from 'react'
import { useState } from 'react'
import { Button, Card, Collapse, Modal, Form, Alert } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Paginate from '../components/Paginate'
import {
  claimRiskFund,
  closeLoan,
  generateReceipt,
  getOneSangha,
  payReceipt,
  renewRiskFund,
  resetState,
  updateReceipt,
} from '../features/sanghaSlice'
import Loader from '../components/Loader'
import { toast } from 'react-toastify'
import ReactToPrint from 'react-to-print'
import PrintReceipt from '../components/prints/PrintReceipt'
const SanghaProfile = () => {
  const [showAliveMembers, setShowAliveMembers] = useState(false)
  const [showDeadMembers, setShowDeadMembers] = useState(false)
  const [showRepayment, setShowRepayment] = useState(false)
  const [showSavings, setShowSavings] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [riskFundShow, setRiskFundShow] = useState(false)
  const [loanClose, setLoanClose] = useState(false)
  const [claimRiskFundShow, setClaimRiskFundShow] = useState(false)
  const [photo, setPhoto] = useState('')
  const [showPhoto, setShowPhoto] = useState(false)
  const [idForRenew, setIdForRenew] = useState('')

  const [receipt, setReceipt] = useState({})
  const [updateReceipt, setReceiptUpdate] = useState({})

  const { id } = useParams()
  const dispatch = useDispatch()

  const componentRef = createRef()

  const {
    sangha,
    isLoading,
    error,
    renewRisk,
    receiptPaid,
    claimRisk,
    closeLoan,
    receiptGen,
    receiptUpdate,
  } = useSelector((state) => state.sangha)
  const { user } = useSelector((state) => state.user)

  useEffect(() => {
    if (renewRisk)
      toast.success('RiskFund  Renewed', {
        position: 'top-right',
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: 'light',
      })
  }, [renewRisk])

  useEffect(() => {
    if (receiptGen)
      toast.success('Receipt Generated', {
        position: 'top-right',
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: 'light',
      })
  }, [receiptGen])

  useEffect(() => {
    if (receiptUpdate) {
      toast.success('Receipt Updated', {
        position: 'top-right',
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: 'light',
      })
      setReceiptUpdate({})
    }
  }, [receiptUpdate])

  useEffect(() => {
    if (claimRisk)
      toast.success('RiskFund  Claimed', {
        position: 'top-right',
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: 'light',
      })
  }, [claimRisk])

  useEffect(() => {
    if (receiptPaid)
      toast.success('Receipt paid ', {
        position: 'top-right',
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: 'light',
      })
  }, [receiptPaid])
  useEffect(() => {
    if (closeLoan)
      toast.success('Loan Closed ', {
        position: 'top-right',
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: 'light',
      })
  }, [closeLoan])

  useEffect(() => {
    dispatch(getOneSangha({ id }))
  }, [
    dispatch,
    id,
    renewRisk,
    receiptPaid,
    claimRisk,
    closeLoan,
    receiptGen,
    receiptUpdate,
  ])

  const navigate = useNavigate()
  return (
    <div className='total-container'>
      <div className='d-flex align-items-center'>
        <Button
          variant='light'
          onClick={() => navigate(-1)}
          className='mx-3 mb-3 px-4'
        >
          {'🡐 Back'}
        </Button>
        <p>Sangha Profile</p>
      </div>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Alert variant='danger'>{error}</Alert>
      ) : (
        <>
          {error && <Alert variant='danger'>{error}</Alert>}
          <div className='mx-3 d-flex flex-wrap gap-3 justify-content-between'>
            <h4>Sangha Details</h4>
            <div>
              <Button onClick={() => setModalShow(true)}>Print Receipt</Button>
              <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                receipt={updateReceipt}
                bankid={
                  sangha.items &&
                  sangha.items.BankData[0] &&
                  sangha.items.BankData[0]._id
                }
                loanAccountNumber={
                  sangha.items && sangha.items.loanAccountNumber
                }
                savingsAccountNumber={
                  sangha.items && sangha.items.savingsAccountNumber
                }
              />
            </div>
          </div>
          <hr />
          {sangha.items && (
            <>
              <div className='mx-3 d-flex flex-wrap justify-content-between gap-5'>
                <div>
                  <h5>Sangha Name : {sangha.items.name}</h5>
                  <p>
                    Formation date : {sangha.items.formationDate.split('T')[0]}
                  </p>
                  <p>Linkage : {sangha.items.linkage}</p>
                  <p>Member Count : {sangha.items.memberCount}</p>
                  <p>
                    Risk Fund :{' '}
                    {sangha.items.riskFundData &&
                      sangha.items.riskFundData.riskAmt}
                  </p>
                  <p>
                    Risk Fund Renewal:{' '}
                    {sangha.items.riskFundData &&
                      sangha.items.riskFundData.riskDate.split('T')[0]}
                  </p>
                  <p>Security Deposit: {sangha.items.securityDeposit}</p>

                  <div className='d-flex flex-wrap gap-3'>
                    <p>Address : </p>
                    <p style={{ whiteSpace: 'pre-line' }}>
                      {sangha.items.address}
                    </p>
                  </div>
                  <div className='d-flex flex-wrap gap-3 my-3'>
                    {sangha.items.sanghaFormPhoto[0] &&
                      sangha.items.sanghaFormPhoto.map((image, id) => (
                        <img
                          src={image}
                          alt={`form${id}`}
                          style={{ height: '100px', cursor: 'pointer' }}
                          onClick={() => {
                            setShowPhoto(true)
                            setPhoto(image)
                          }}
                        />
                      ))}
                  </div>
                </div>

                <div>
                  <h5>
                    Bank Name :{' '}
                    {sangha.items.BankData[0] &&
                      sangha.items.BankData[0].name +
                        ' , ' +
                        sangha.items.BankData[0].branchName}
                  </h5>
                  <p>Loan Amount : {sangha.items.loanAmount}</p>
                  <p>
                    Repayment Start Date :{' '}
                    {sangha.items.repaymentStartDate.split('T')[0]}
                  </p>
                  <p>
                    Fixed Repayment Date : {sangha.items.repaymentFixedDate}
                  </p>
                  <p>Loan date : {sangha.items.loanDate.split('T')[0]}</p>
                  <p>Loan Account : {sangha.items.loanAccountNumber}</p>
                  <p>Savings Account : {sangha.items.savingsAccountNumber}</p>
                  <p>Fixed Repayment Amount : {sangha.items.repaymentAmount}</p>
                  {sangha.items.loanCloseDetails ? (
                    <div className='border p-3 rounded-3'>
                      <p>
                        Closure Date :{' '}
                        {
                          sangha.items.loanCloseDetails.closureDate.split(
                            'T'
                          )[0]
                        }
                      </p>
                      <p style={{ whiteSpace: 'pre-line' }}>
                        Closure Date : {sangha.items.loanCloseDetails.remarks}
                      </p>
                    </div>
                  ) : (
                    <Button onClick={() => setLoanClose(true)}>
                      Close Loan
                    </Button>
                  )}
                </div>
              </div>
              <div className='mt-3'>
                {(user.type === 'Admin' || user.type === 'Manager') && (
                  <Link to='edit'>
                    <Button onClick={() => dispatch(resetState())}>
                      Edit Sangha Details
                    </Button>
                  </Link>
                )}
              </div>
              <hr />
              <div className='d-flex flex-wrap gap-5  m-3'>
                {sangha.items.representatives &&
                  sangha.items.representatives.map((ele) => {
                    return (
                      <div className='d-flex flex-wrap gap-3'>
                        <h6 className='mt-4'>
                          Represtative {ele.memberType === 'rep1' ? '1' : '2'} :{' '}
                        </h6>
                        <div>
                          <Card className='p-3 h-100'>
                            <h6 className='text-center'>
                              Members Name : {ele.memberName}
                            </h6>
                            <h6 className='text-center'>
                              Village : {ele.village}
                            </h6>

                            <p className='text-center'>
                              Father/Spouce Name : {ele.fatherOrSpouseName}
                            </p>
                            <p className='text-center'>
                              DoB : {ele.DOB.split('T')[0]}
                            </p>

                            <p className='text-center'>
                              Contact No : {ele.contactNo}
                            </p>
                            {ele.reclaimData && ele.reclaimData.reclaim ? (
                              <>
                                <p className='text-center'>
                                  Reclaim Amount : {ele.reclaimData.amount}
                                </p>
                                <p className='text-center'>
                                  Death Date :{' '}
                                  {ele.reclaimData.deathDate.split('T')[0]}
                                </p>
                                <p className='text-center'>
                                  Reason : {ele.reclaimData.reason}
                                </p>
                                <h6 className='text-center'>Remarks :</h6>
                                <p className='text-center'>
                                  {ele.reclaimData.remarks}
                                </p>
                              </>
                            ) : (
                              <>
                                <table className='table table-bordered'>
                                  <thead>
                                    <tr>
                                      <th>Amount</th>
                                      <th>Renewal</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {ele.riskFund &&
                                      ele.riskFund.map((ele) => {
                                        return (
                                          <tr>
                                            <td>{ele.amount}</td>
                                            <td>
                                              {ele.renewDate.split('T')[0]}
                                            </td>
                                          </tr>
                                        )
                                      })}
                                  </tbody>
                                </table>
                                <div className='mt-auto'>
                                  <Button
                                    className='w-100'
                                    onClick={() => {
                                      setRiskFundShow(true)
                                      setIdForRenew(ele._id)
                                    }}
                                  >
                                    Renew RiskFund
                                  </Button>
                                  <Button
                                    className='mt-3 w-100'
                                    onClick={() => {
                                      setClaimRiskFundShow(true)
                                      setIdForRenew(ele._id)
                                    }}
                                  >
                                    Claim RiskFund
                                  </Button>
                                </div>
                              </>
                            )}
                          </Card>
                        </div>
                      </div>
                    )
                  })}
              </div>
            </>
          )}

          <hr />

          <div
            className='d-flex flex-wrap gap-3 justify-content-between align-items-end cursor-pointer mx-3'
            onClick={() => setShowAliveMembers(!showAliveMembers)}
          >
            <div className='d-flex gap-4 flex-wrap'>
              <h5 className='m-0'>Sangha Members Details(Alive)</h5>
            </div>
            <p
              className='m-0 cursor-pointer small'
              onClick={() => setShowAliveMembers(!showAliveMembers)}
            >
              {showAliveMembers ? 'Show Less' : 'Show more'}
            </p>
          </div>
          <Collapse in={showAliveMembers}>
            <div className='total-container'>
              <div className='d-flex justify-content-center flex-wrap'>
                {sangha.items &&
                  sangha.items.aliveMembers &&
                  sangha.items.aliveMembers.map((ele) => {
                    return (
                      <div className='col-12 col-md-4 col-lg-3 card-container'>
                        <Card className='p-3 h-100'>
                          <h6 className='text-center'>
                            Members Name : {ele.memberName}
                          </h6>
                          <h6 className='text-center'>
                            Village : {ele.village}
                          </h6>

                          <p className='text-center'>
                            Father/Spouce Name : {ele.fatherOrSpouseName}
                          </p>
                          <p className='text-center'>
                            DoB : {ele.DOB.split('T')[0]}
                          </p>

                          <p className='text-center'>
                            Contact No : {ele.contactNo}
                          </p>
                          <table className='table table-bordered'>
                            <thead>
                              <tr>
                                <th>Amount</th>
                                <th>Renewal</th>
                              </tr>
                            </thead>
                            <tbody>
                              {ele.riskFund &&
                                ele.riskFund.map((ele) => {
                                  return (
                                    <tr>
                                      <td>{ele.amount}</td>
                                      <td>{ele.renewDate.split('T')[0]}</td>
                                    </tr>
                                  )
                                })}
                            </tbody>
                          </table>
                          {sangha.items &&
                            sangha.items.riskFundData &&
                            new Date(sangha.items.riskFundData.riskDate) >
                              new Date() && (
                              <div className='mt-auto'>
                                <Button
                                  className='w-100'
                                  onClick={() => {
                                    setRiskFundShow(true)
                                    setIdForRenew(ele._id)
                                  }}
                                >
                                  Renew RiskFund
                                </Button>
                                <Button
                                  className='mt-3 w-100'
                                  onClick={() => {
                                    setClaimRiskFundShow(true)
                                    setIdForRenew(ele._id)
                                  }}
                                >
                                  Claim RiskFund
                                </Button>
                              </div>
                            )}
                        </Card>
                      </div>
                    )
                  })}
              </div>
            </div>
          </Collapse>

          <hr />
          <div
            className='d-flex flex-wrap gap-3 justify-content-between align-items-end cursor-pointer mx-3'
            onClick={() => setShowDeadMembers(!showDeadMembers)}
          >
            <div className='d-flex gap-4 flex-wrap'>
              <h5 className='m-0'>Sangha Members Details(Dead)</h5>
            </div>
            <p
              className='m-0 cursor-pointer small'
              onClick={() => setShowDeadMembers(!showDeadMembers)}
            >
              {showDeadMembers ? 'Show Less' : 'Show more'}
            </p>
          </div>
          <Collapse in={showDeadMembers}>
            <div className='total-container'>
              <div className='d-flex justify-content-center flex-wrap'>
                {sangha.items &&
                  sangha.items.deadMembers &&
                  sangha.items.deadMembers.map((ele) => {
                    return (
                      <div className='col-12 col-md-4 col-lg-3 card-container'>
                        <Card className='p-3 h-100'>
                          <h6 className='text-center'>
                            Members Name : {ele.memberName}
                          </h6>
                          <h6 className='text-center'>
                            Village : {ele.village}
                          </h6>
                          <p className='text-center'>
                            Father/Spouce Name : {ele.fatherOrSpouseName}
                          </p>
                          <p className='text-center'>
                            DoB : {ele.DOB.split('T')[0]}
                          </p>

                          <p className='text-center'>
                            Contact No : {ele.contactNo}
                          </p>
                          {ele.reclaimData && (
                            <>
                              <p className='text-center'>
                                Reclaim Amount : {ele.reclaimData.amount}
                              </p>
                              <p className='text-center'>
                                Death Date :{' '}
                                {ele.reclaimData.deathDate.split('T')[0]}
                              </p>
                              <p className='text-center'>
                                Reason : {ele.reclaimData.reason}
                              </p>
                              <h6 className='text-center'>Remarks :</h6>
                              <p className='text-center'>
                                {ele.reclaimData.remarks}
                              </p>
                            </>
                          )}
                        </Card>
                      </div>
                    )
                  })}
              </div>
            </div>
          </Collapse>

          <hr />
          <div
            className='d-flex flex-wrap gap-3 justify-content-between align-items-end cursor-pointer mx-3'
            onClick={() => setShowRepayment(!showRepayment)}
          >
            <div className='d-flex gap-4 flex-wrap'>
              <h5 className='m-0'>Sangha Repayment Details</h5>
              <h6 className='ms-3 fs-6'>
                Total Repayment :{' '}
                {sangha.items && sangha.items.TotalRepaymentAmount}
              </h6>
            </div>
            <p
              className='m-0 cursor-pointer small'
              onClick={() => setShowRepayment(!showRepayment)}
            >
              {showRepayment ? 'Show Less' : 'Show more'}
            </p>
          </div>
          <Collapse in={showRepayment}>
            <div className='total-container'>
              <div className='d-flex justify-content-center flex-wrap'>
                {sangha.items &&
                  sangha.items.RepaymentData &&
                  sangha.items.RepaymentData.map((ele) => {
                    return (
                      <div className='col-12 col-md-4 col-lg-3 card-container'>
                        <Card className='p-3 h-100'>
                          <h6 className='text-center'>
                            Repayment amount : {ele.amount}
                          </h6>
                          <h6 className='text-center'>
                            Receipt Number : {ele.receiptId}
                          </h6>

                          <p className='text-center'>
                            Receipt Date :{' '}
                            {ele.receiptGenerationDate &&
                              ele.receiptGenerationDate.split('T')[0]}
                          </p>

                          <p className='text-center'>
                            FieldOfficer :{' '}
                            {ele.employeeData[0] && ele.employeeData[0].name}
                          </p>
                          {ele.paymentDate ? (
                            <p className='text-center'>
                              Payment Date : {ele.paymentDate.split('T')[0]}
                            </p>
                          ) : (
                            <>
                              <p>Payment Status : pending</p>
                              <Button
                                onClick={() => {
                                  dispatch(payReceipt({ id: ele._id }))
                                }}
                              >
                                Pay
                              </Button>
                              {user.type === 'Admin' && (
                                <Button
                                  className='mt-2'
                                  onClick={() => {
                                    setReceiptUpdate(ele)
                                    setModalShow(true)
                                  }}
                                >
                                  Edit
                                </Button>
                              )}
                            </>
                          )}
                          <div className='mt-auto'>
                            <hr />
                            <ReactToPrint
                              trigger={() => (
                                <Button className='px-4 w-100'>
                                  Print Receipt
                                </Button>
                              )}
                              content={() => componentRef.current}
                              onBeforeGetContent={() => {
                                setReceipt(ele)
                                return Promise.resolve()
                              }}
                            />
                          </div>
                        </Card>
                      </div>
                    )
                  })}
              </div>
              <div className='d-flex justify-content-end me-3'>
                <Paginate
                  type='repayment'
                  page={sangha.currentRepaymentPage}
                  pages={sangha.totalRepaymentPages}
                />
              </div>
            </div>
          </Collapse>
          <hr />
          <div
            className='d-flex flex-wrap gap-3 justify-content-between align-items-end cursor-pointer mx-3'
            onClick={() => setShowSavings(!showSavings)}
          >
            <div className='d-flex gap-4 flex-wrap'>
              <h5 className='m-0'>Sangha Savings Details</h5>
              <h6 className='ms-3 fs-6'>
                Total Savings :{' '}
                {sangha.items && sangha.items.TotalSavingsAmount}
              </h6>
            </div>
            <p
              className='m-0 cursor-pointer small'
              onClick={() => setShowSavings(!showSavings)}
            >
              {showSavings ? 'Show Less' : 'Show more'}
            </p>
          </div>
          <Collapse in={showSavings}>
            <div className='total-container'>
              <div className='d-flex justify-content-center flex-wrap'>
                {sangha.items &&
                  sangha.items.SavingsData &&
                  sangha.items.SavingsData.map((ele) => {
                    return (
                      <div className='col-12 col-md-4 col-lg-3 card-container'>
                        <Card className='p-3 h-100'>
                          <h6 className='text-center'>
                            Savings amount : {ele.amount}
                          </h6>
                          <h6 className='text-center'>
                            Receipt Number : {ele.receiptId}
                          </h6>

                          <p className='text-center'>
                            Receipt Date :{' '}
                            {ele.receiptGenerationDate &&
                              ele.receiptGenerationDate.split('T')[0]}
                          </p>
                          <p className='text-center'>
                            FieldOfficer :{' '}
                            {ele.employeeData[0] && ele.employeeData[0].name}
                          </p>
                          <div className='mt-auto'>
                            {user.type === 'Admin' && (
                              <Button
                                className='mt-2 w-100'
                                onClick={() => {
                                  setReceiptUpdate(ele)
                                  setModalShow(true)
                                }}
                              >
                                Edit
                              </Button>
                            )}
                            <hr />
                            <ReactToPrint
                              trigger={() => (
                                <Button className='px-4 w-100'>
                                  Print Receipt
                                </Button>
                              )}
                              content={() => componentRef.current}
                              onBeforeGetContent={() => {
                                setReceipt(ele)
                                return Promise.resolve()
                              }}
                            />
                          </div>
                        </Card>
                      </div>
                    )
                  })}
              </div>
              <div className='d-flex justify-content-end me-3'>
                <Paginate
                  type='savings'
                  page={sangha.currentSavingPage}
                  pages={sangha.totalSavingPages}
                />
              </div>
            </div>
          </Collapse>
          <hr />
          <RiskFundRenewal
            show={riskFundShow}
            onHide={() => setRiskFundShow(false)}
            idForRenew={idForRenew}
          />
          <RiskFundClaim
            show={claimRiskFundShow}
            onHide={() => setClaimRiskFundShow(false)}
            idForRenew={idForRenew}
          />
          <CloseLoan show={loanClose} onHide={() => setLoanClose(false)} />
          <ShowPhoto
            show={showPhoto}
            onHide={() => setShowPhoto(false)}
            photo={photo}
          />

          <div style={{ display: 'none' }}>
            <PrintReceipt
              ref={componentRef}
              receipt={{
                ...receipt,
                loanAccountNumber:
                  sangha.items && sangha.items.loanAccountNumber,
                repaymentAmount: sangha.items && sangha.items.repaymentAmount,
                loanAmount: sangha.items && sangha.items.loanAmount,
                savingsAccountNumber:
                  sangha.items && sangha.items.savingsAccountNumber,
                name: sangha.items && sangha.items.name,
              }}
            />
          </div>
        </>
      )}
    </div>
  )
}

function MyVerticallyCenteredModal({
  savingsAccountNumber,
  bankid,
  loanAccountNumber,

  receipt,
  ...props
}) {
  const [data, setData] = useState({ amount: '', type: '', village: 'na' })

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    if (receipt._id) {
      setData({ ...data, amount: receipt.amount, type: receipt.type })
    }
  }, [receipt._id])

  const { id } = useParams()
  const [error, setError] = useState(false)
  const dispatch = useDispatch()
  const onSubmit = (e) => {
    e.preventDefault()
    var temp = false
    for (const key in data) {
      if (!data[key]) {
        temp = true
        break
      }
    }

    if (!temp) {
      if (receipt._id) {
        dispatch(updateReceipt({ id: receipt._id, data }))
      } else {
        dispatch(
          generateReceipt({
            id,
            data: {
              ...data,
              savingsAccountNumber,
              bankId: bankid,
              loanAccountNumber,
              sanghaId: id,
            },
          })
        )
      }
      props.onHide()

      setData({
        amount: '',
        type: '',
        village: 'na',
      })
    }
    setError(temp)
  }
  return (
    <Modal
      {...props}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          Create Receipt
        </Modal.Title>
      </Modal.Header>
      <Form>
        <Modal.Body>
          <Form.Group className='mb-3 w-100'>
            <Form.Label>Receipt Type</Form.Label>
            <Form.Select name='type' value={data.type} onChange={onChange}>
              <option value=''>Select Receipt Type</option>
              <option value='Savings'>Savings</option>
              <option value='Loan'>Repaymnent</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className='mb-3 w-100'>
            <Form.Label>Amount</Form.Label>
            <Form.Control
              className={`w-100 ${error && !data.amount && 'is-invalid'}`}
              type='number'
              placeholder=' Enter Amount'
              required
              min={1}
              name='amount'
              value={data.amount}
              onChange={onChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onSubmit}>Submit</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

function RiskFundRenewal({ idForRenew, ...props }) {
  const [data, setData] = useState({
    amount: '',
    renewDate: '',
    riskFundStartDate: '',
  })

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const { id } = useParams()
  const [error, setError] = useState(false)
  const dispatch = useDispatch()
  const onSubmit = (e) => {
    e.preventDefault()
    var temp = false
    for (const key in data) {
      if (!data[key]) {
        temp = true
        break
      }
    }

    if (!temp) {
      dispatch(renewRiskFund({ id, data: { ...data, memberId: idForRenew } }))
      props.onHide()

      setData({
        amount: '',
        renewDate: '',
        riskFundStartDate: '',
      })
    }
    setError(temp)
  }
  return (
    <Modal
      {...props}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          Risk Fund Renewal
        </Modal.Title>
      </Modal.Header>
      <Form>
        <Modal.Body>
          <Form.Group className='mb-3 w-100'>
            <Form.Label>Enter Member Risk Fund Amount</Form.Label>
            <Form.Control
              className={`w-100 ${error && !data.amount && 'is-invalid'}`}
              type='text'
              placeholder='Enter Enter Amount'
              required
              name='amount'
              value={data.amount}
              onChange={onChange}
            />
            <div className='invalid-feedback'>This Field is required</div>
          </Form.Group>
          <Form.Group className='mb-3 w-100'>
            <Form.Label>Enter Risk Fund Payment Date</Form.Label>
            <Form.Control
              className={`w-100 ${error && !data.paymentDate && 'is-invalid'}`}
              type='date'
              placeholder='Enter Payment Date'
              required
              name='paymentDate'
              value={data.paymentDate}
              onChange={onChange}
            />
            <div className='invalid-feedback'>This Field is required</div>
          </Form.Group>
          <Form.Group className='mb-3 w-100'>
            <Form.Label>Enter Risk Fund Amount</Form.Label>
            <Form.Control
              className={`w-100 ${error && !data.renewDate && 'is-invalid'}`}
              type='date'
              placeholder='Enter Enter Renewal Date'
              required
              name='renewDate'
              value={data.renewDate}
              onChange={onChange}
            />
            <div className='invalid-feedback'>This Field is required</div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onSubmit}>Submit</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

function RiskFundClaim({ idForRenew, ...props }) {
  const [data, setData] = useState({
    amount: '',
    reason: '',
    deathDate: '',
    remarks: '',
  })

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const { id } = useParams()
  const [error, setError] = useState(false)
  const dispatch = useDispatch()
  const onSubmit = (e) => {
    e.preventDefault()
    var temp = false
    for (const key in data) {
      if (!data[key]) {
        temp = true
        break
      }
    }

    if (!temp) {
      dispatch(claimRiskFund({ id, data: { ...data, memberId: idForRenew } }))
      props.onHide()

      setData({
        amount: '',
      })
    }
    setError(temp)
  }
  return (
    <Modal
      {...props}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          Risk Fund Claim
        </Modal.Title>
      </Modal.Header>
      <Form>
        <Modal.Body>
          <Form.Group className='mb-3 w-100'>
            <Form.Label> Amount</Form.Label>
            <Form.Control
              className={`w-100 ${error && !data.amount && 'is-invalid'}`}
              type='text'
              placeholder='Enter Amount'
              required
              name='amount'
              value={data.amount}
              onChange={onChange}
            />
            <div className='invalid-feedback'>This Field is required</div>
          </Form.Group>
          <Form.Group className='mb-3 w-100'>
            <Form.Label> Death Date</Form.Label>
            <Form.Control
              className={`w-100 ${error && !data.deathDate && 'is-invalid'}`}
              type='date'
              required
              name='deathDate'
              value={data.deathDate}
              onChange={onChange}
            />
            <div className='invalid-feedback'>This Field is required</div>
          </Form.Group>

          <Form.Group className='mb-3 w-100'>
            <Form.Label> Reason</Form.Label>
            <Form.Control
              className={`w-100 ${error && !data.reason && 'is-invalid'}`}
              type='text'
              required
              name='reason'
              value={data.reason}
              onChange={onChange}
            />
            <div className='invalid-feedback'>This Field is required</div>
          </Form.Group>
          <Form.Group className='mb-3 w-100'>
            <Form.Label> remarks</Form.Label>
            <Form.Control
              className={`w-100 ${error && !data.remarks && 'is-invalid'}`}
              type='text'
              as='textarea'
              required
              name='remarks'
              value={data.remarks}
              onChange={onChange}
            />
            <div className='invalid-feedback'>This Field is required</div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onSubmit}>Submit</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

function CloseLoan({ idForRenew, ...props }) {
  const [data, setData] = useState({ remarks: '' })

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const { id } = useParams()
  const [error, setError] = useState(false)
  const dispatch = useDispatch()
  const onSubmit = (e) => {
    e.preventDefault()
    var temp = false
    for (const key in data) {
      if (!data[key]) {
        temp = true
        break
      }
    }

    if (!temp) {
      dispatch(closeLoan({ id, data }))
      props.onHide()

      setData({
        remarks: '',
      })
    }
    setError(temp)
  }
  return (
    <Modal
      {...props}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          Risk Fund Renewal
        </Modal.Title>
      </Modal.Header>
      <Form>
        <Modal.Body>
          <Form.Group className='mb-3 w-100'>
            <Form.Label>Remarks</Form.Label>
            <Form.Control
              className={`w-100 ${error && !data.remarks && 'is-invalid'}`}
              as='textarea'
              type='text'
              placeholder='Enter Remarks'
              required
              name='remarks'
              value={data.remarks}
              onChange={onChange}
            />
            <div className='invalid-feedback'>This Field is required</div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onSubmit}>Submit</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

function ShowPhoto({ photo, ...props }) {
  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <div className='d-flex justify-content-center'>
        <img
          src={photo}
          alt='Form photo1'
          style={{ maxWidth: '100%', height: 'auto', width: 'auto' }}
        />
      </div>
    </Modal>
  )
}

export default SanghaProfile
