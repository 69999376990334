import Footer from './components/Footer'
import Header from './components/Header'
import LoginScreen from './screens/loginScreen/LoginScreen'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import HomePage from './screens/HomePage'
import CreateBank from './screens/createBankScreen/CreateBank'
import CreateEmployee from './screens/CreateEmployee'
import CreateSangha from './screens/CreateSangha'
import SavingsList from './screens/SavingsList'
import DuePayment from './screens/DuePayment'
import DemandList from './screens/DemandList'
import SecurityDeposit from './screens/SecurityDeposit'
import LendingReport from './screens/LendingReport'
import RiskFundList from './screens/RiskFundList'
import SanghaList from './screens/SanghaList'
import EmployeeList from './screens/EmployeeList'
import BankList from './screens/BankList'
import SanghaProfile from './screens/SanghaProfile'
import EmployeeProfile from './screens/EmployeeProfile'
import BankProfile from './screens/BankProfile'
import { ToastContainer } from 'react-toastify'
import RepaymentList from './screens/RepaymentList'
import PageNotFound from './screens/PageNotFound'
import { Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import UserProfile from './screens/UserProfile'
import { logout } from './features/userSlice'
import IncomeReport from './screens/IncomeReportScreen'
import SoftwareDownload from './screens/SoftwareDownload'

const AuthUser = ({ children }) => {
  const { user } = useSelector((state) => state.user)

  if (user) return <>{children}</>
  else return <Navigate to='/login' />
}

const AdminAuth = ({ children }) => {
  const { user } = useSelector((state) => state.user)
  const dispatch = useDispatch()

  if (user && (user.type === 'Admin' || user.type === 'Manager'))
    return <>{children}</>
  else {
    dispatch(logout())
    return <Navigate to='/login' />
  }
}

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Container className='mt-3'>
        <Routes>
          <Route path='/'>
            <Route
              index
              element={
                <AuthUser>
                  <HomePage />
                </AuthUser>
              }
            />
            <Route path='software' element={<SoftwareDownload />} />

            <Route path='login' element={<LoginScreen />} />
            <Route
              path='create-bank'
              element={
                <AuthUser>
                  <AdminAuth>
                    <CreateBank />
                  </AdminAuth>
                </AuthUser>
              }
            />
            <Route
              path='user-profile'
              element={
                <AuthUser>
                  <UserProfile />
                </AuthUser>
              }
            />
            <Route
              path='create-employee'
              element={
                <AuthUser>
                  <AdminAuth>
                    <CreateEmployee />
                  </AdminAuth>
                </AuthUser>
              }
            />
            <Route
              path='create-sangha'
              element={
                <AuthUser>
                  <CreateSangha />
                </AuthUser>
              }
            />
            <Route
              path='savings-list'
              element={
                <AuthUser>
                  <SavingsList />
                </AuthUser>
              }
            />
            <Route
              path='repayment-list'
              element={
                <AuthUser>
                  <RepaymentList />
                </AuthUser>
              }
            />
            <Route
              path='due-payment-list'
              element={
                <AuthUser>
                  <DuePayment />
                </AuthUser>
              }
            />
            <Route
              path='demand-list'
              element={
                <AuthUser>
                  <DemandList />
                </AuthUser>
              }
            />
            <Route
              path='income-report'
              element={
                <AuthUser>
                  <IncomeReport />
                </AuthUser>
              }
            />
            <Route
              path='security-deposit-list'
              element={
                <AuthUser>
                  <SecurityDeposit />
                </AuthUser>
              }
            />
            <Route
              path='risk-fund-list'
              element={
                <AuthUser>
                  <RiskFundList />
                </AuthUser>
              }
            />
            <Route
              path='lending-report-list'
              element={
                <AuthUser>
                  <LendingReport />
                </AuthUser>
              }
            />
            <Route path='sangha-list'>
              <Route
                index
                element={
                  <AuthUser>
                    <SanghaList />
                  </AuthUser>
                }
              />
              <Route path=':id'>
                <Route
                  index
                  element={
                    <AuthUser>
                      <SanghaProfile />
                    </AuthUser>
                  }
                />
                <Route
                  path='edit'
                  element={
                    <AuthUser>
                      <AdminAuth>
                        <CreateSangha />
                      </AdminAuth>
                    </AuthUser>
                  }
                />
              </Route>
            </Route>
            <Route path='employee-list'>
              <Route
                index
                element={
                  <AuthUser>
                    <AdminAuth>
                      <EmployeeList />
                    </AdminAuth>
                  </AuthUser>
                }
              />
              <Route path=':id'>
                <Route
                  index
                  element={
                    <AuthUser>
                      <AdminAuth>
                        <EmployeeProfile />
                      </AdminAuth>
                    </AuthUser>
                  }
                />
                <Route
                  path='edit'
                  element={
                    <AuthUser>
                      <AdminAuth>
                        <CreateEmployee />
                      </AdminAuth>
                    </AuthUser>
                  }
                />
              </Route>
            </Route>

            <Route path='bank-list'>
              <Route
                index
                element={
                  <AuthUser>
                    <AdminAuth>
                      <BankList />
                    </AdminAuth>
                  </AuthUser>
                }
              />
              <Route path=':id'>
                <Route
                  index
                  element={
                    <AuthUser>
                      <AdminAuth>
                        <BankProfile />
                      </AdminAuth>
                    </AuthUser>
                  }
                />
                <Route
                  path='edit'
                  element={
                    <AuthUser>
                      <AdminAuth>
                        <CreateBank />
                      </AdminAuth>
                    </AuthUser>
                  }
                />
              </Route>
            </Route>
          </Route>
          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </Container>
      <Footer />
      <ToastContainer />
    </BrowserRouter>
  )
}

export default App
