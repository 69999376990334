import React from 'react'

const PrintSavingsReport = React.forwardRef(({ lending, filter }, ref) => {
  const getDate = (date) => {
    const DueDate = new Date(date)

    var dd = String(DueDate.getDate()).padStart(2, '0')
    var mm = String(DueDate.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = DueDate.getFullYear()
    return dd + '/' + mm + '/' + yyyy
  }

  return (
    <div ref={ref} className='m-5'>
      <h5 className='m-3 text-center'>Savings report</h5>
      <h6 className='m-3 text-center'>filters : {filter}</h6>
      <table className='table table-bordered'>
        <thead>
          <tr>
            <th scope='col'>#</th>
            <th scope='col'>SHG NAME</th>
            <th scope='col'>ADDRESS</th>
            <th scope='col'>SANGHA TYPE</th>
            <th scope='col'>NEW/RENEWAL</th>

            <th scope='col'>BANK NAME</th>
            <th scope='col'>BRANCH NAME</th>

            <th scope='col'>TOTAL NO OF MEMBERS </th>
            <th scope='col'>LOAN AMOUNT</th>
            <th scope='col'>LOAN DATE</th>
            <th scope='col'>REPAYMENT START DATE</th>
            <th scope='col'>REPAYMENT DATE</th>
            <th scope='col'>REPAYMENT AMONT</th>
            <th scope='col'>S B A/C NUMBER</th>
            <th scope='col'>LOAN A/C NUMBER</th>
            <th scope='col'>TOTAL SAVINGS</th>
            <th scope='col'>FIELD OFFICER NAME</th>
          </tr>
        </thead>
        <tbody>
          {lending &&
            lending.map((lending, id) => {
              return (
                <tr key={lending._id}>
                  <th scope='row'>{id + 1}</th>
                  <td>{lending.name}</td>
                  <td>{lending.address}</td>
                  <td>{lending.type}</td>

                  <td>{lending.newSangha ? 'New' : 'Renew'}</td>

                  <td>{lending.BankData[0] && lending.BankData[0].name}</td>
                  <td>
                    {lending.BankData[0] && lending.BankData[0].branchName}
                  </td>

                  <td>{lending.memberCount}</td>
                  <td>{lending.loanAmount}</td>
                  <td>{getDate(lending.loanDate)}</td>
                  <td>{getDate(lending.repaymentStartDate)}</td>
                  <td>{lending.repaymentFixedDate}</td>
                  <td>{lending.repaymentAmount}</td>
                  <td>{lending.savingsAccountNumber}</td>
                  <td>{lending.loanAccountNumber}</td>
                  <td>{lending.totalSavings}</td>
                  <td>
                    {lending.EmployeeData[0] && lending.EmployeeData[0].name}
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
    </div>
  )
})

export default PrintSavingsReport
